import "./index.scss";
import Button from "../common/Button";
import PhoneInput from "../common/PhoneInput";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import json from "../../lottie/bicycle.json";
import GlobalState, { send, subscribe } from "../../stores/GlobalState";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { observer } from "mobx-react";

const Intro = observer(() => {
  const navigate = useNavigate();
  const content: any = GlobalState.content?.intro;
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const submit = () => {
    const phone = value
      .replaceAll("+", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");
      
        if (!value.length || value.includes("_")) {
      setError(content.error);
      (document.querySelector(".intro__form") as HTMLElement).style.setProperty(
        "--input-error",
        JSON.stringify(content.error)
      );
      return;
    } else setError("");

    subscribe(phone).then(() => {
      navigate("/thanks");
    });
  };

  useEffect(() => {
    const s: any = document.querySelector(".intro");
    if (!s) return;
    const header = document.querySelector(".header");
    if (!header) return;
    let vh =
      (window.innerHeight -
        header!.getBoundingClientRect().height -
        parseInt(getComputedStyle(s).marginTop)) *
      0.01;
    (s as HTMLElement).style.setProperty("--vh", `${vh}px`);

    document.querySelector(".intro")?.addEventListener("resize", () => {
      let vh =
        (window.innerHeight -
          header!.getBoundingClientRect().height -
          parseInt(getComputedStyle(s).marginTop)) *
        0.01;
      (s as HTMLElement).style.setProperty("--vh", `${vh}px`);
    });
  }, [content]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {}, [content]);

  if (!content) return <></>;

  return (
    <section className={classNames("intro")}>
      <div className="intro__container">
        <div className="intro__row">
          <div className="intro__col">
            <div className="intro__subtitle animated fadeInUp wow">
              {content.subtitle}
            </div>
            <div
              className="intro__title animated fadeInUp wow"
              dangerouslySetInnerHTML={{ __html: content.title }}
            ></div>
            <div className="intro__text animated fadeInUp delay-1s wow">
              {content.text}
            </div>
            <div
              className={classNames(
                "intro__form animated fadeInUp delay-1s wow",
                error.length && "error"
              )}
              data-wow-delay="1.25s"
            >
              <PhoneInput
                placeholder={content.placeholder}
                getValue={(value) => setValue(value)}
              />
              <Button text={content.button} click={submit} />
            </div>
          </div>
          <div className="intro__img animated wow" data-wow-duration="1.25s">
            <Lottie options={defaultOptions} />
          </div>
        </div>
        <div className="intro__bottom animated fadeInUp " id="course">
          <div className="intro__left">
            {content.list.map((li: any, i: number) => (
              <div className="intro__item" key={i}>
                <div className="intro__item-icon">
                  <img src={li.icon} alt="design.kitchen" />
                </div>
                {li.title}
              </div>
            ))}
          </div>
          <div className="intro__right">
            {content.socials.map((si: any, i: number) => (
              <a
                href={si.link}
                target="__blank"
                className="intro__social"
                key={i}
              >
                <img src={si.icon} alt="design.kitchen" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
});

export default Intro;
