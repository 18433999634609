import './index.scss'
import { ReactComponent as Close } from '../../assets/pop/close-outline (2) 1.svg'

import classNames from 'classnames'
import { observer } from 'mobx-react'
import GlobalState, { changePopState } from '../../stores/GlobalState'
import { useEffect } from 'react'

const PopUp = observer(() => {
  const content: any = {
    user: GlobalState.review,
  }

  useEffect(() => {
    if (GlobalState.isPopShow) {
      document.querySelector('body')?.classList.add('hidden-scroll')
      document.querySelector('html')?.classList.add('hidden-scroll')
    } else {
      setTimeout(() => {
        document.querySelector('.pop-up__content')?.scroll(0, 0)
      }, 300)
      document.querySelector('body')?.classList.remove('hidden-scroll')
      document.querySelector('html')?.classList.remove('hidden-scroll')
    }
  }, [GlobalState.isPopShow])

  return (
    <section
      className={classNames('pop-up', GlobalState.isPopShow && 'show')}
      onClick={changePopState}
    >
      <section
        className="pop-up__container"
        onClick={(e) => e.stopPropagation()}
      >
        <Close className="pop-up__close" onClick={changePopState} />
        <div className="pop-up__title">{content.user?.maintitle}</div>
        <div className="pop-up__content">
          <div
            className="pop-up__text"
            dangerouslySetInnerHTML={{ __html: content.user?.text }}
          ></div>
          <div className="pop-up__user">
            <div className="pop-up__user-col">
              <div className="pop-up__user-img">
                <img src={content.user?.src} alt="design.kitchen" />
              </div>
              <div className="pop-up__user-subcol">
                <div className="pop-up__user-title">{content.user?.title}</div>
                <div className="pop-up__user-text">
                  {content.user?.subtitle}
                </div>
              </div>
            </div>
            <div className="pop-up__user-socials">
              {content.user?.socials.map((si: any, i: number) => (
                <a href={si.link} key={i} target="__blank">
                  <img src={si.icon} alt="design.kitchen" />
                </a>
              ))}
            </div>
          </div>
          <div className="pop-up__works">
            {content.user?.works.map((w: any, i: number) => (
              <a
                className="pop-up__work"
                key={i}
                href={w.link}
                target="__blank"
              >
                <img src={w.img} alt="design.kitchen" />
              </a>
            ))}
          </div>
        </div>
      </section>
    </section>
  )
})

export default PopUp
