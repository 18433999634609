import './index.scss'
import { ReactComponent as Plus } from '../../assets/faq/plus.svg'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'

const Faq = observer(() => {
  const [active, setActive] = useState(0)
  const content = GlobalState.content?.faq

  useEffect(() => {}, [content])

  if (!content) return <></>
  return (
    <section className="faq">
      <div className="faq__container">
        <div className="faq__col">
          <div className="faq__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="faq__list">
            {content.list.map((l: any, i: number) => (
              <div
                className={classNames(
                  'faq__item wow animated fadeInUp',
                  active == i && 'active',
                )}
                key={i}
                onClick={() => {
                  if (active == i) {
                    setActive(-1)
                  } else setActive(i)
                }}
              >
                <div className="faq__item-top">
                  {l.title}
                  <span>
                    <Plus />
                  </span>
                </div>
                <div
                  className="faq__item-text"
                  dangerouslySetInnerHTML={{ __html: l.text }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
})

export default Faq
