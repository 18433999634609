import './index.scss'
import img from "../../../assets/footer/Frame (8).svg";
import { ReactComponent as Arrows } from '../../../assets/footer/Arrows.svg'
import { ReactComponent as Logo } from '../../../assets/footer/logo.svg'
import { useEffect, useState } from 'react'
import GlobalState from '../../../stores/GlobalState'
import { observer } from 'mobx-react'

const Footer = observer(() => {
  const content = GlobalState.content?.footer
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  if (!content) return <></>
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__img">
          <img src={img} alt="design.kitchen" />
        </div>
        <div className="footer__row">
          <div className="footer__col">
            <div className="footer__col-title">{content.contactTitle}</div>
            {content.contacts.map((s: any, i: number) => (
              <a
                href={s.link}
                className="footer__social filter-footer"
                key={i}
                target="__blank"
              >
                <span>
                  {' '}
                  <img src={s.icon} alt="design.kitchen" />
                </span>
                {s.text}
              </a>
            ))}
            <div className="footer__links">
              {content.links.map((s: any, i: number) => (
                <a
                  href={s.link}
                  className="footer__social footer-underline"
                  target={'_blank'}
                  key={i}
                >
                  {s.title}
                </a>
              ))}
            </div>
            {width <= 1024 && (
              <p className="footer__copy">
                {' '}
                © {new Date().getFullYear()} Design Kitchen
              </p>
            )}
          </div>
          <div className="footer__col">
            <div className="footer__title">{content.title}</div>
            <div className="footer__text">{content.text}</div>
            <div className="footer__socials">
              {content.socials.map((s: any, i: number) => (
                <a
                  href={s.link}
                  className="footer__social"
                  key={i}
                  target="__blank"
                >
                  <span>
                    {' '}
                    <img src={s.icon} alt="design.kitchen" />
                  </span>
                </a>
              ))}
            </div>
            <div className="footer__bottom">
              <div className="footer__bottom-col">
                {width > 1024 && (
                  <p> © {new Date().getFullYear()} Design Kitchen</p>
                )}
                <p
                  className="own-site"
                  onClick={() =>
                    window.open('https://equal.design/', '__blank')
                  }
                >
                  {' '}
                  Design & development <Logo />
                </p>
              </div>
              <div
                className="footer__bottom-arrow"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              >
                <span>
                  <Arrows />
                </span>
                <span> {content.up}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
