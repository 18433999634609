import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import Ask from '../components/Ask'
import Awwards from '../components/Awwards'
import Footer from '../components/common/Footer'
import Header from '../components/common/Header'
import Companies from '../components/Companies'
import Cost from '../components/Cost'
import Faq from '../components/Faq'
import Formats from '../components/Formats'
import Intro from '../components/Intro'
import MainForm from '../components/MainForm'
import PopUp from '../components/PopUp'
import Portfolio from '../components/Portfolio'
import Prefer from '../components/Prefer'
import Program from '../components/Program'
import Results from '../components/Results'
import Reviews from '../components/Reviews'
import Subscribe from '../components/Subscribe'
import Teachers from '../components/Teachers'
import GlobalState, { setContent } from '../stores/GlobalState'
import scrollFunc from '../components/MainForm/scrollFunc'
const WOW = require('wow.js')
const Home = observer(() => {
  const ref = useRef<any>(false)
  useEffect(() => {
    if (ref.current) return
    setContent()
    ref.current = true
  }, [])

  useEffect(() => {
    if (GlobalState.content && ref.current) {
      new WOW().init()

      setTimeout(() => {
        scrollFunc()
      }, 100)
    }
  }, [GlobalState.content])

  return (
    <>
      <Header />
      <Intro />
      <Formats />
      <MainForm />
      <Prefer />
      <Program />
      <Teachers />
      <Portfolio />
      <Reviews />
      <Companies />
      <Results />
      <Cost />
      <Awwards />
      <Subscribe />
      <Faq />
      <Ask />
      <Footer />
      <PopUp />
    </>
  )
})

export default Home
