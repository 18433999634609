import './index.scss'

import { useEffect } from 'react'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'

const Prefer = observer(() => {
  const content = GlobalState.content?.prefer

  if (!content) return <></>
  return (
    <section className="prefer">
      <div className="prefer__container">
        <div className="prefer__top">
          <div className="prefer__title wow fadeInUp animated">
            {content.title}
          </div>
          <div className="prefer__text wow fadeInUp animated">
            {content.text}
          </div>
        </div>
        <div className="prefer__list">
          {content.list.map((li: any, i: number) => (
            <div
              className="prefer__item wow fadeInUp animated"
              key={i}
              data-wow-duration="0.75s"
              data-wow-delay={`${0.15 * i}s`}
            >
              <div
                className="prefer__item-icon wow zoomIn animated"
                data-wow-duration="0.5s"
                data-wow-delay={`${0.55 + 0.15 * i}s`}
                dangerouslySetInnerHTML={{ __html: li.icon }}
              ></div>
              <div className="prefer__item-title">{li.title}</div>
              <div className="prefer__item-text">{li.text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})

export default Prefer
