import { useState } from "react";
import Button from "../common/Button";
import PhoneInput from "../common/PhoneInput";
import "./index.scss";
import Lottie from "react-lottie";
import json from "../../lottie/horse.json";
import GlobalState, { send } from "../../stores/GlobalState";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { observer } from "mobx-react";
const Subscribe = observer(() => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const content = GlobalState.content?.subscribe;

  const submit = () => {
    const phone = value
      .replaceAll("+", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");

    if (!value.length || value.includes("_")) {
      setError(content.error);
      (
        document.querySelector(".subscribe__label") as HTMLElement
      ).style.setProperty("--input-error", JSON.stringify(content.error));
      return;
    } else setError("");
    send({ phone: value }).then(() => {
      navigate("/thanks");
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (!content) return <></>;
  return (
    <section className="subscribe">
      <div className="subscribe__container">
        <div className="subscribe__col">
          <div className="subscribe__subtitle wow animated fadeInUp">
            {content.subtitle}
          </div>
          <div className="subscribe__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="subscribe__text wow animated fadeInUp">
            {content.text}
          </div>
          <div
            className="subscribe__row  wow animated fadeInUp"
            data-wow-duration="1.25s"
            data-wow-delay="0.5s"
          >
            <div
              className={classNames(
                "subscribe__label",
                error.length && "error"
              )}
            >
              <PhoneInput
                placeholder={content.placeholder}
                getValue={(value) => setValue(value)}
              />
            </div>
            <Button text={content.buttonTitle} click={submit} />
          </div>
        </div>
        <div className="subscribe__img  wow animated fadeInUp">
          <Lottie options={defaultOptions} />
        </div>
      </div>
    </section>
  );
});

export default Subscribe;
