import al from "../assets/flags/Property 1=AL - Albania.svg";
import DZ from "../assets/flags/Property 1=DZ - Algeria.svg";
import AF from "../assets/flags/Property 1=AF - Afghanistan.svg";
import AR from "../assets/flags/Property 1=AR - Argentina.svg";
import AE from "../assets/flags/Property 1=AE - United Arab Emirates.svg";
import OM from "../assets/flags/Property 1=OM - Oman.svg";
import AZ from "../assets/flags/Property 1=AZ - Azerbaijan.svg";
import EG from "../assets/flags/Property 1=EG - Egypt.svg";
import ET from "../assets/flags/Property 1=ET - Ethiopia.svg";
import IE from "../assets/flags/Property 1=IE - Ireland.svg";
import EE from "../assets/flags/Property 1=EE - Estonia.svg";
import AD from "../assets/flags/Property 1=AD - Andorra.svg";
import AO from "../assets/flags/Property 1=AO - Angola.svg";
import AI from "../assets/flags/Property 1=AI - Anguilla.svg";
import AG from "../assets/flags/Property 1=AG - Antigua and Barbuda.svg";
import AT from "../assets/flags/Property 1=AT - Austria.svg";
import AU from "../assets/flags/Property 1=AU - Australia.svg";
import MO from "../assets/flags/Property 1=MO - Macao.svg";
import BB from "../assets/flags/Property 1=BB - Barbados.svg";
import PG from "../assets/flags/Property 1=PG - Papua New Guinea.svg";
import BS from "../assets/flags/Property 1=BS - Bahamas.svg";
import PK from "../assets/flags/Property 1=PK - Pakistan.svg";
import PY from "../assets/flags/Property 1=PY - Paraguay.svg";
import PS from "../assets/flags/Property 1=PS - Palestinian Territory.svg";
import BH from "../assets/flags/Property 1=BH - Bahrain.svg";
import PA from "../assets/flags/Property 1=PA - Panama.svg";
import BR from "../assets/flags/Property 1=BR - Brazil.svg";
import BM from "../assets/flags/Property 1=BM - Bermuda.svg";
import BG from "../assets/flags/Property 1=BG - Bulgaria.svg";
import MP from "../assets/flags/Property 1=MP - Northern Mariana Islands.svg";
import BJ from "../assets/flags/Property 1=BJ - Benin.svg";
import BE from "../assets/flags/Property 1=BE - Belgium.svg";
import IS from "../assets/flags/Property 1=IS - Iceland.svg";
import PR from "../assets/flags/Property 1=PR - Puerto Rico.svg";
import PL from "../assets/flags/Property 1=PL - Poland.svg";
import BA from "../assets/flags/Property 1=BA - Bosnia and Herzegovina.svg";
import BO from "../assets/flags/Property 1=BO - Bolivia.svg";
import BZ from "../assets/flags/Property 1=BZ - Belize.svg";
import PW from "../assets/flags/Property 1=PW - Palau.svg";
import BW from "../assets/flags/Property 1=BW - Botswana.svg";
import BT from "../assets/flags/Property 1=BT - Bhutan.svg";
import BF from "../assets/flags/Property 1=BF - Burkina Faso.svg";
import BI from "../assets/flags/Property 1=BI - Burundi.svg";
import GQ from "../assets/flags/Property 1=GQ - Equatorial Guinea.svg";
import DK from "../assets/flags/Property 1=DK - Denmark.svg";
import DE from "../assets/flags/Property 1=DE - Germany.svg";
import TL from "../assets/flags/Property 1=TL - Timor-Leste.svg";
import TG from "../assets/flags/Property 1=TG - Togo.svg";
import DM from "../assets/flags/Property 1=DM - Dominica.svg";
import DO from "../assets/flags/Property 1=DO - Dominican Republic.svg";
import EC from "../assets/flags/Property 1=EC - Ecuador.svg";
import ER from "../assets/flags/Property 1=ER - Eritrea.svg";
import FR from "../assets/flags/Property 1=FR - France.svg";
import FO from "../assets/flags/Property 1=FO - Faroe Islands.svg";
import PF from "../assets/flags/Property 1=PF - French Polynesia.svg";
import VA from "../assets/flags/Property 1=VA - Vatican City State.svg";
import PH from "../assets/flags/Property 1=PH - Philippines.svg";
import FJ from "../assets/flags/Property 1=FJ - Fiji.svg";
import FI from "../assets/flags/Property 1=FI - Finland.svg";
import CV from "../assets/flags/Property 1=CV - Cabo Verde.svg";
import FK from "../assets/flags/Property 1=FK - Falkland Islands.svg";
import GM from "../assets/flags/Property 1=GM - Gambia.svg";
import CG from "../assets/flags/Property 1=CG - Republic of the Congo.svg";
import CD from "../assets/flags/Property 1=CD - Democratic Republic of the Congo.svg";
import CO from "../assets/flags/Property 1=CO - Colombia.svg";
import CR from "../assets/flags/Property 1=CR - Costa Rica.svg";
import GD from "../assets/flags/Property 1=GD - Grenada.svg";
import GL from "../assets/flags/Property 1=GL - Greenland.svg";
import GE from "../assets/flags/Property 1=GE - Georgia.svg";
import GG from "../assets/flags/Property 1=GG - Guernsey.svg";
import CU from "../assets/flags/Property 1=CU - Cuba.svg";
import GU from "../assets/flags/Property 1=GU - Guam.svg";
import GY from "../assets/flags/Property 1=GY - Guyana.svg";
import KZ from "../assets/flags/Property 1=KZ - Kazakhstan.svg";
import HT from "../assets/flags/Property 1=HT - Haiti.svg";
import KR from "../assets/flags/Property 1=KR - Korea (South).svg";
import NL from "../assets/flags/Property 1=NL - Netherlands.svg";
import ME from "../assets/flags/Property 1=ME - Montenegro.svg";
import HN from "../assets/flags/Property 1=HN - Honduras.svg";
import KI from "../assets/flags/Property 1=KI -  Kiribati.svg";
import DJ from "../assets/flags/Property 1=DJ - Djibouti.svg";
import KG from "../assets/flags/Property 1=KG - Kyrgyzstan.svg";
import GN from "../assets/flags/Property 1=GN - Guinea.svg";
import GW from "../assets/flags/Property 1=GW - Guinea-Bissau.svg";
import CA from "../assets/flags/Property 1=CA - Canada.svg";
import GH from "../assets/flags/Property 1=GH - Ghana.svg";
import KH from "../assets/flags/Property 1=KH - Cambodia.svg";
import GA from "../assets/flags/Property 1=GA - Gabon.svg";
import CZ from "../assets/flags/Property 1=CZ - Czech Republic.svg";
import ZW from "../assets/flags/Property 1=ZW - Zimbabwe.svg";
import CM from "../assets/flags/Property 1=CM - Cameroon.svg";
import QA from "../assets/flags/Property 1=QA - Qatar.svg";
import KY from "../assets/flags/Property 1=KY - Cayman Islands.svg";
import CN from "../assets/flags/Property 1=CN - China.svg";
import CF from "../assets/flags/Property 1=CF - Central African Republic.svg";
import CL from "../assets/flags/Property 1=CL - Chile.svg";
import GI from "../assets/flags/Property 1=GI - Gibraltar.svg";
import TD from "../assets/flags/Property 1=TD - Chad.svg";
import JE from "../assets/flags/Property 1=JE - Jersey.svg";
import ZM from "../assets/flags/Property 1=ZM - Zambia.svg";
import VN from "../assets/flags/Property 1=VN - Vietnam.svg";
import JO from "../assets/flags/Property 1=JO - Jordan.svg";
import VG from "../assets/flags/Property 1=VG - Virgin Islands (British).svg";
import GB from "../assets/flags/Property 1=GB-UKM - United Kingdom.svg";
import ID from "../assets/flags/Property 1=ID - Indonesia.svg";
import IN from "../assets/flags/Property 1=IN - India.svg";
import IT from "../assets/flags/Property 1=IT - Italy.svg";
import IL from "../assets/flags/Property 1=IL - Isreal.svg";
import IR from "../assets/flags/Property 1=IR - Iran.svg";
import IQ from "../assets/flags/Property 1=IQ - Iraq.svg";
import YE from "../assets/flags/Property 1=YE - Yemen.svg";
import AM from "../assets/flags/Property 1=AM - Armenia.svg";
import JM from "../assets/flags/Property 1=JM - Jamaica.svg";
import SY from "../assets/flags/Property 1=SY - Syria.svg";
import HU from "../assets/flags/Property 1=HU - Hungary.svg";
import NZ from "../assets/flags/Property 1=NZ - New Zealand (Aotearoa).svg";
import NC from "../assets/flags/Property 1=NC - New Caledonia.svg";
import SG from "../assets/flags/Property 1=SG - Singapore.svg";
import HK from "../assets/flags/Property 1=HK - Hong Kong.svg";
import EH from "../assets/flags/Property 1=EH - Western Sahara.svg";
import ES from "../assets/flags/Property 1=ES - Spain.svg";
import GR from "../assets/flags/Property 1=GR - Greece.svg";
import UZ from "../assets/flags/Property 1=UZ - Uzbekistan.svg";
import UY from "../assets/flags/Property 1=UY - Uruguay.svg";
import UA from "../assets/flags/Property 1=UA - Ukraine.svg";
import UG from "../assets/flags/Property 1=UG - Uganda.svg";
import BN from "../assets/flags/Property 1=BN - Brunei Darussalam.svg";
import VE from "../assets/flags/Property 1=VE - Venezuela.svg";
import GT from "../assets/flags/Property 1=GT - Guatemala.svg";
import VU from "../assets/flags/Property 1=VU - Vanuatu.svg";
import WF from "../assets/flags/Property 1=WF - Wallis and Futuna.svg";
import TK from "../assets/flags/Property 1=TK - Tokelau.svg";
import TM from "../assets/flags/Property 1=TM - Turkmenistan.svg";
import TR from "../assets/flags/Property 1=TR - Turkey.svg";
import TV from "../assets/flags/Property 1=TV - Tuvalu.svg";
import TN from "../assets/flags/Property 1=TN - Tunisia.svg";
import TT from "../assets/flags/Property 1=TT - Trinidad and Tobago.svg";
import TC from "../assets/flags/Property 1=TC - Turks and Caicos Islands.svg";

import TO from "../assets/flags/Property 1=TO - Tonga.svg";
import TZ from "../assets/flags/Property 1=TZ - Tanzania.svg";
import TH from "../assets/flags/Property 1=TH - Thailand.svg";
import TW from "../assets/flags/Property 1=TW - Taiwan.svg";
import TJ from "../assets/flags/Property 1=TJ - Tajikistan.svg";
import SO from "../assets/flags/Property 1=SO - Somalia.svg";

import SB from "../assets/flags/Property 1=SB - Solomon Islands.svg";
import SR from "../assets/flags/Property 1=SR - Suriname.svg";
import SD from "../assets/flags/Property 1=SD - Sudan.svg";
import SI from "../assets/flags/Property 1=SI - Slovenia.svg";
import SK from "../assets/flags/Property 1=SK - Slovakia.svg";
import LK from "../assets/flags/Property 1=LK - Sri Lanka.svg";
import VC from "../assets/flags/Property 1=VC - Saint Vincent and the Grenadines.svg";
import SM from "../assets/flags/Property 1=SM - San Marino.svg";
import MF from "../assets/flags/Property 1=MF - Saint Martin.svg";
import LC from "../assets/flags/Property 1=LC - Saint Lucia.svg";
import KN from "../assets/flags/Property 1=KN - Saint Kitts and Nevis.svg";
import SH from "../assets/flags/Property 1=SH - Saint Helena.svg";
import ST from "../assets/flags/Property 1=ST - Sao Tome and Principe.svg";
import SA from "../assets/flags/Property 1=SA - Saudi Arabia.svg";
import SC from "../assets/flags/Property 1=SC - Seychelles.svg";
import CY from "../assets/flags/Property 1=CY - Cyprus.svg";
import SN from "../assets/flags/Property 1=SN - Senegal.svg";
import SL from "../assets/flags/Property 1=SL - Sierra Leone.svg";

import RS from "../assets/flags/Property 1=RS - Serbia.svg";
import WS from "../assets/flags/Property 1=WS - Samoa.svg";
import SV from "../assets/flags/Property 1=SV - El Salvador.svg";
import SE from "../assets/flags/Property 1=SE - Sweden.svg";
import CH from "../assets/flags/Property 1=CH - Switzerland.svg";
import JP from "../assets/flags/Property 1=JP - Japan.svg";
import PT from "../assets/flags/Property 1=PT - Portugal.svg";
import NF from "../assets/flags/Property 1=NF - Norfolk Island.svg";
import NO from "../assets/flags/Property 1=NO - Norway.svg";
import NU from "../assets/flags/Property 1=NU - Niue.svg";
import NG from "../assets/flags/Property 1=NG - Nigeria.svg";
import NE from "../assets/flags/Property 1=NE - Niger.svg";
import NP from "../assets/flags/Property 1=NP - Nepal.svg";
import NI from "../assets/flags/Property 1=NI - Nicaragua.svg";
import NR from "../assets/flags/Property 1=NR - Nauru.svg";
import SS from "../assets/flags/Property 1=SS - South Sudan.svg";
import ZA from "../assets/flags/Property 1=ZA - South Africa.svg";
import NA from "../assets/flags/Property 1=NA - Namibia.svg";
import MX from "../assets/flags/Property 1=MX - Mexico.svg";
import MZ from "../assets/flags/Property 1=MZ - Mozambique.svg";
import MC from "../assets/flags/Property 1=MC - Monaco.svg";
import MA from "../assets/flags/Property 1=MA - Morocco.svg";
import MD from "../assets/flags/Property 1=MD - Moldova.svg";
import MM from "../assets/flags/Property 1=MM - Myanmar.svg";
import PE from "../assets/flags/Property 1=PE - Peru.svg";
import BD from "../assets/flags/Property 1=BD - Bangladesh.svg";


//
import MS from "../assets/flags/Property 1=MS - Montserrat.svg";
import MN from "../assets/flags/Property 1=MN - Mongolia.svg";
import AS from "../assets/flags/Property 1=AS - American Samoa.svg";
import US from "../assets/flags/Property 1=US - United States.svg";
import MR from "../assets/flags/Property 1=MR - Mauritania.svg";
import MU from "../assets/flags/Property 1=MU - Mauritius.svg";
import YT from "../assets/flags/Property 1=YT-UNF - Mayotte.svg";
import MQ from "../assets/flags/Property 1=MQ - Martinique-1.svg";
import MH from "../assets/flags/Property 1=MH - Marshall Islands.svg";
import MK from "../assets/flags/Property 1=MK - North Macedonia.svg";
import ML from "../assets/flags/Property 1=ML - Mali.svg";
import MY from "../assets/flags/Property 1=MY - Malaysia.svg";
import MW from "../assets/flags/Property 1=MW - Malawi.svg";
import MT from "../assets/flags/Property 1=MT - Malta.svg";
import MV from "../assets/flags/Property 1=MV - Maldives.svg";
import IM from "../assets/flags/Property 1=IM - Isle of Man.svg";
import MG from "../assets/flags/Property 1=MG - Madagascar.svg";
import RO from "../assets/flags/Property 1=RO - Romania.svg";
import RW from "../assets/flags/Property 1=RW - Rwanda.svg";
import LU from "../assets/flags/Property 1=LU - Luxembourg.svg";
import RE from "../assets/flags/Property 1=RE - Reunion.svg";
import LI from "../assets/flags/Property 1=LI - Liechtenstein.svg";
import LY from "../assets/flags/Property 1=LY - Libya.svg";
import LR from "../assets/flags/Property 1=LR - Liberia.svg";
import LT from "../assets/flags/Property 1=LT - Lithuania.svg";
import LB from "../assets/flags/Property 1=LB - Lebanon.svg";
import LA from "../assets/flags/Property 1=LA - Laos.svg";
import LS from "../assets/flags/Property 1=LS - Lesotho.svg";
import LV from "../assets/flags/Property 1=LV - Latvia.svg";
import CW from "../assets/flags/Property 1=CW - Curaçao.svg";
import CK from "../assets/flags/Property 1=CK - Cook Islands.svg";
import KE from "../assets/flags/Property 1=KE - Kenia.svg";
import HR from "../assets/flags/Property 1=HR - Croatia (Hrvatska).svg";
import KW from "../assets/flags/Property 1=KW - Kuwait.svg";
import KM from "../assets/flags/Property 1=KM - Comoros.svg";
import CI from "../assets/flags/Property 1=CI - Côte d'Ivoire (Ivory Coast).svg";

const phonecodes = [
  {
    country_code: "AL",
    country_en: "Албанія",
    country_cn: "阿尔巴尼亚",
    phone_code: 355,
    icon: al,
  },
  {
    country_code: "DZ",
    country_en: "Алжир",
    country_cn: "阿尔及利亚",
    phone_code: 213,
    icon: DZ,
  },
  {
    country_code: "AF",
    country_en: "Афганістан",
    country_cn: "阿富汗",
    phone_code: 93,
    icon: AF,
  },
  {
    country_code: "AR",
    country_en: "Аргентина",
    country_cn: "阿根廷",
    phone_code: 54,
    icon: AR,
  },
  {
    country_code: "AE",
    country_en: "Об'єднані Арабські Емірати",
    country_cn: "阿联酋",
    phone_code: 971,
    icon: AE,
  },
  {
    country_code: "OM",
    country_en: "Оман",
    country_cn: "阿曼",
    phone_code: 968,
    icon: OM,
  },
  {
    country_code: "AZ",
    country_en: "Азербайджан",
    country_cn: "阿塞拜疆",
    phone_code: 994,
    icon: AZ,
  },
  {
    country_code: "EG",
    country_en: "Єгипет",
    country_cn: "埃及",
    phone_code: 20,
    icon: EG,
  },
  {
    country_code: "ET",
    country_en: "Ефіопія",
    country_cn: "埃塞俄比亚",
    phone_code: 251,
    icon: ET,
  },
  {
    country_code: "IE",
    country_en: "Ірландія",
    country_cn: "爱尔兰",
    phone_code: 353,
    icon: IE,
  },
  {
    country_code: "EE",
    country_en: "Естонія",
    country_cn: "爱沙尼亚",
    phone_code: 372,
    icon: EE,
  },
  {
    country_code: "AD",
    country_en: "Андорра",
    country_cn: "安道尔",
    phone_code: 376,
    icon: AD,
  },
  {
    country_code: "AO",
    country_en: "Ангола",
    country_cn: "安哥拉",
    phone_code: 244,
    icon: AO,
  },
  {
    country_code: "AI",
    country_en: "Ангілья",
    country_cn: "安圭拉",
    phone_code: 1264,
    icon: AI,
  },
  {
    country_code: "AG",
    country_en: "Антигуа",
    country_cn: "安提瓜岛",
    phone_code: 1268,
    icon: AG,
  },
  {
    country_code: "AT",
    country_en: "Австрія",
    country_cn: "奥地利",
    phone_code: 43,
    icon: AT,
  },
  {
    country_code: "AU",
    country_en: "Австралія",
    country_cn: "澳大利亚",
    phone_code: 61,
    icon: AU,
  },
  {
    country_code: "MO",
    country_en: "Макао",
    country_cn: "中国澳门特别行政区",
    phone_code: 853,
    icon: MO,
  },
  {
    country_code: "BB",
    country_en: "Барбадос",
    country_cn: "巴巴多斯",
    phone_code: 1246,
    icon: BB,
  },
  {
    country_code: "PG",
    country_en: "Папуа Нова Гвінея",
    country_cn: "巴布亚新几内亚",
    phone_code: 675,
    icon: PG,
  },
  {
    country_code: "BS",
    country_en: "Багамські Острови",
    country_cn: "巴哈马",
    phone_code: 1242,
    icon: BS,
  },
  {
    country_code: "PK",
    country_en: "Пакистан",
    country_cn: "巴基斯坦",
    phone_code: 92,
    icon: PK,
  },
  {
    country_code: "PY",
    country_en: "Парагвай",
    country_cn: "巴拉圭",
    phone_code: 595,
    icon: PY,
  },
  {
    country_code: "PS",
    country_en: "Палестина",
    country_cn: "巴勒斯坦",
    phone_code: 970,
    icon: PS,
  },
  {
    country_code: "BH",
    country_en: "Бахрейн",
    country_cn: "巴林",
    phone_code: 973,
    icon: BH,
  },
  {
    country_code: "PA",
    country_en: "Панама",
    country_cn: "巴拿马",
    phone_code: 507,
    icon: PA,
  },
  {
    country_code: "BR",
    country_en: "Бразилія",
    country_cn: "巴西",
    phone_code: 55,
    icon: BR,
  },
  {
    country_code: "BM",
    country_en: "Бермуди",
    country_cn: "百慕大",
    phone_code: 1441,
    icon: BM,
  },
  {
    country_code: "BG",
    country_en: "Болгарія",
    country_cn: "保加利亚",
    phone_code: 359,
    icon: BG,
  },
  {
    country_code: "MP",
    country_en: "Північні Маріани",
    country_cn: "北马里亚纳群岛",
    phone_code: 1670,
    icon: MP,
  },
  {
    country_code: "BJ",
    country_en: "Бенін",
    country_cn: "贝宁",
    phone_code: 229,
    icon: BJ,
  },
  {
    country_code: "BE",
    country_en: "Бельгія",
    country_cn: "比利时",
    phone_code: 32,
    icon: BE,
  },
  {
    country_code: "IS",
    country_en: "Ісландія",
    country_cn: "冰岛",
    phone_code: 354,
    icon: IS,
  },
  {
    country_code: "PR",
    country_en: "Пуерто-Рико",
    country_cn: "波多黎各",
    phone_code: 1,
    icon: PR,
  },
  {
    country_code: "PL",
    country_en: "Польща",
    country_cn: "波兰",
    phone_code: 48,
    icon: PL,
  },
  {
    country_code: "BA",
    country_en: "Боснія і Герцеговина",
    country_cn: "波斯尼亚和黑塞哥维那",
    phone_code: 387,
    icon: BA,
  },
  {
    country_code: "BO",
    country_en: "Болівія",
    country_cn: "玻利维亚",
    phone_code: 591,
    icon: BO,
  },
  {
    country_code: "BZ",
    country_en: "Беліз",
    country_cn: "伯利兹",
    phone_code: 501,
    icon: BZ,
  },
  {
    country_code: "PW",
    country_en: "Палау",
    country_cn: "帛琉",
    phone_code: 680,
    icon: PW,
  },
  {
    country_code: "BW",
    country_en: "Ботсвана",
    country_cn: "博茨瓦纳",
    phone_code: 267,
    icon: BW,
  },
  {
    country_code: "BT",
    country_en: "Бутан",
    country_cn: "不丹",
    phone_code: 975,
    icon: BT,
  },
  {
    country_code: "BF",
    country_en: "Буркіна-Фасо",
    country_cn: "布基纳法索",
    phone_code: 226,
    icon: BF,
  },
  {
    country_code: "BI",
    country_en: "Бурунді",
    country_cn: "布隆迪",
    phone_code: 257,
    icon: BI,
  },
  {
    country_code: "GQ",
    country_en: "Екваторіальна Гвінея",
    country_cn: "赤道几内亚",
    phone_code: 240,
    icon: GQ,
  },
  {
    country_code: "DK",
    country_en: "Данія",
    country_cn: "丹麦",
    phone_code: 45,
    icon: DK,
  },
  {
    country_code: "DE",
    country_en: "Германія",
    country_cn: "德国",
    phone_code: 49,
    icon: DE,
  },
  {
    country_code: "TL",
    country_en: "Східний Тимор",
    country_cn: "东帝汶",
    phone_code: 670,
    icon: TL,
  },
  {
    country_code: "TG",
    country_en: "Того",
    country_cn: "多哥",
    phone_code: 228,
    icon: TG,
  },
  {
    country_code: "DM",
    country_en: "Домініка",
    country_cn: "多米尼加",
    phone_code: 1767,
    icon: DM,
  },
  {
    country_code: "DO",
    country_en: "Домініканська Республіка",
    country_cn: "多米尼加共和国",
    phone_code: 1809,
    icon: DO,
  },
  {
    country_code: "EC",
    country_en: "Еквадор",
    country_cn: "厄瓜多尔",
    phone_code: 593,
    icon: EC,
  },
  {
    country_code: "ER",
    country_en: "Еритрея",
    country_cn: "厄立特里亚",
    phone_code: 291,
    icon: ER,
  },
  {
    country_code: "FR",
    country_en: "Франція",
    country_cn: "法国",
    phone_code: 33,
    icon: FR,
  },
  {
    country_code: "FO",
    country_en: "Фарери",
    country_cn: "法罗群岛",
    phone_code: 298,
    icon: FO,
  },
  {
    country_code: "PF",
    country_en: "Французька Полінезія",
    country_cn: "法属波利尼西亚",
    phone_code: 689,
    icon: PF,
  },
  {
    country_code: "VA",
    country_en: "Ватикан",
    country_cn: "梵蒂冈城",
    phone_code: 39,
    icon: VA,
  },
  {
    country_code: "PH",
    country_en: "Філіппіни",
    country_cn: "菲律宾",
    phone_code: 63,
    icon: PH,
  },
  {
    country_code: "FJ",
    country_en: "Фіджі",
    country_cn: "斐济",
    phone_code: 679,
    icon: FJ,
  },
  {
    country_code: "FI",
    country_en: "Фінляндія",
    country_cn: "芬兰",
    phone_code: 358,
    icon: FI,
  },
  {
    country_code: "CV",
    country_en: "Кабо-Верде",
    country_cn: "佛得角",
    phone_code: 238,
    icon: CV,
  },
  {
    country_code: "FK",
    country_en: "Фолкленди",
    country_cn: "福克兰群岛",
    phone_code: 500,
    icon: FK,
  },
  {
    country_code: "GM",
    country_en: "Гамбія",
    country_cn: "冈比亚",
    phone_code: 220,
    icon: GM,
  },
  {
    country_code: "CG",
    country_en: "Республіка Конго",
    country_cn: "刚果共和国",
    phone_code: 242,
    icon: CG,
  },
  {
    country_code: "CD",
    country_en: "ДР Конго",
    country_cn: "刚果民主共和国",
    phone_code: 243,
    icon: CD,
  },
  {
    country_code: "CO",
    country_en: "Колумбія",
    country_cn: "哥伦比亚",
    phone_code: 57,
    icon: CO,
  },
  {
    country_code: "CR",
    country_en: "Коста-Рика",
    country_cn: "哥斯达黎加",
    phone_code: 506,
    icon: CR,
  },
  {
    country_code: "GD",
    country_en: "Гренада",
    country_cn: "格林纳达岛",
    phone_code: 1473,
    icon: GD,
  },
  {
    country_code: "GL",
    country_en: "Гренландія",
    country_cn: "格陵兰",
    phone_code: 299,
    icon: GL,
  },
  {
    country_code: "GE",
    country_en: "Грузія",
    country_cn: "格鲁吉亚",
    phone_code: 995,
    icon: GE,
  },
  {
    country_code: "GG",
    country_en: "Гернсі",
    country_cn: "根西岛",
    phone_code: 44,
    icon: GG,
  },
  {
    country_code: "CU",
    country_en: "Куба",
    country_cn: "古巴",
    phone_code: 53,
    icon: CU,
  },
  {
    country_code: "GU",
    country_en: "Гуам",
    country_cn: "关岛",
    phone_code: 1671,
    icon: GU,
  },
  {
    country_code: "GY",
    country_en: "Гаяна",
    country_cn: "圭亚那",
    phone_code: 592,
    icon: GY,
  },
  {
    country_code: "KZ",
    country_en: "Казахстан",
    country_cn: "哈萨克斯坦",
    phone_code: 7,
    icon: KZ,
  },
  {
    country_code: "HT",
    country_en: "Гаїті",
    country_cn: "海地",
    phone_code: 509,
    icon: HT,
  },
  {
    country_code: "KR",
    country_en: "Південна Корея",
    country_cn: "韩国",
    phone_code: 82,
    icon: KR,
  },
  {
    country_code: "NL",
    country_en: "Нідерланди",
    country_cn: "荷兰",
    phone_code: 31,
    icon: NL,
  },

  {
    country_code: "ME",
    country_en: "Чорногорія",
    country_cn: "黑山共和国",
    phone_code: 382,
    icon: ME,
  },
  {
    country_code: "HN",
    country_en: "Гондурас",
    country_cn: "洪都拉斯",
    phone_code: 504,
    icon: HN,
  },
  {
    country_code: "KI",
    country_en: "Кірибаті",
    country_cn: "基里巴斯",
    phone_code: 686,
    icon: KI,
  },
  {
    country_code: "DJ",
    country_en: "Джибуті",
    country_cn: "吉布提",
    phone_code: 253,
    icon: DJ,
  },
  {
    country_code: "KG",
    country_en: "Киргизстан",
    country_cn: "吉尔吉斯斯坦",
    phone_code: 996,
    icon: KG,
  },
  {
    country_code: "GN",
    country_en: "Гвінея",
    country_cn: "几内亚",
    phone_code: 224,
    icon: GN,
  },
  {
    country_code: "GW",
    country_en: "Гвінея-Бісау",
    country_cn: "几内亚比绍共和国",
    phone_code: 245,
    icon: GW,
  },
  {
    country_code: "CA",
    country_en: "Канада",
    country_cn: "加拿大",
    phone_code: 1,
    icon: CA,
  },
  {
    country_code: "GH",
    country_en: "Гана",
    country_cn: "加纳",
    phone_code: 233,
    icon: GH,
  },
  {
    country_code: "GA",
    country_en: "Габон",
    country_cn: "加蓬共和国",
    phone_code: 241,
    icon: GA,
  },
  {
    country_code: "KH",
    country_en: "Камбоджа",
    country_cn: "柬埔寨",
    phone_code: 855,
    icon: KH,
  },
  {
    country_code: "CZ",
    country_en: "Чехія",
    country_cn: "捷克",
    phone_code: 420,
    icon: CZ,
  },
  {
    country_code: "ZW",
    country_en: "Зімбабве",
    country_cn: "津巴布韦",
    phone_code: 263,
    icon: ZW,
  },
  {
    country_code: "CM",
    country_en: "Камерун",
    country_cn: "喀麦隆",
    phone_code: 237,
    icon: CM,
  },
  {
    country_code: "QA",
    country_en: "Катар",
    country_cn: "卡塔尔",
    phone_code: 974,
    icon: QA,
  },
  {
    country_code: "KY",
    country_en: "Кайманові Острови",
    country_cn: "开曼群岛",
    phone_code: 1345,
    icon: KY,
  },
  {
    country_code: "KM",
    country_en: "Коморські Острови",
    country_cn: "科摩罗",
    phone_code: 269,
    icon: KM,
  },
  {
    country_code: "CI",
    country_en: "Кот-д'Івуар",
    country_cn: "科特迪瓦",
    phone_code: 225,
    icon: CI,
  },
  {
    country_code: "KW",
    country_en: "Кувейт",
    country_cn: "科威特",
    phone_code: 965,
    icon: KW,
  },
  {
    country_code: "HR",
    country_en: "Хорватія",
    country_cn: "克罗地亚",
    phone_code: 385,
    icon: HR,
  },
  {
    country_code: "KE",
    country_en: "Кенія",
    country_cn: "肯尼亚",
    phone_code: 254,
    icon: KE,
  },
  {
    country_code: "CK",
    country_en: "Острови Кука",
    country_cn: "库克群岛",
    phone_code: 682,
    icon: CK,
  },
  {
    country_code: "CW",
    country_en: "Кюрасао",
    country_cn: "库拉索",
    phone_code: 599,
    icon: CW,
  },
  {
    country_code: "LV",
    country_en: "Латвія",
    country_cn: "拉脱维亚",
    phone_code: 371,
    icon: LV,
  },
  {
    country_code: "LS",
    country_en: "Лесото",
    country_cn: "莱索托",
    phone_code: 266,
    icon: LS,
  },
  {
    country_code: "LA",
    country_en: "Лаос",
    country_cn: "老挝",
    phone_code: 856,
    icon: LA,
  },
  {
    country_code: "LB",
    country_en: "Ліван",
    country_cn: "黎巴嫩",
    phone_code: 961,
    icon: LB,
  },
  {
    country_code: "LT",
    country_en: "Литва",
    country_cn: "立陶宛",
    phone_code: 370,
    icon: LT,
  },
  {
    country_code: "LR",
    country_en: "Ліберія",
    country_cn: "利比里亚",
    phone_code: 231,
    icon: LR,
  },
  {
    country_code: "LY",
    country_en: "Лівія",
    country_cn: "利比亞",
    phone_code: 218,
    icon: LY,
  },
  {
    country_code: "LI",
    country_en: "Ліхтенштейн",
    country_cn: "列支敦士登",
    phone_code: 423,
    icon: LI,
  },
  {
    country_code: "RE",
    country_en: "Реюньйон",
    country_cn: "留尼汪",
    phone_code: 262,
    icon: RE,
  },
  {
    country_code: "LU",
    country_en: "Люксембург",
    country_cn: "卢森堡",
    phone_code: 352,
    icon: LU,
  },
  {
    country_code: "RW",
    country_en: "Руанда",
    country_cn: "卢旺达",
    phone_code: 250,
    icon: RW,
  },
  {
    country_code: "RO",
    country_en: "Румунія",
    country_cn: "罗马尼亚",
    phone_code: 40,
    icon: RO,
  },
  {
    country_code: "MG",
    country_en: "Мадагаскар",
    country_cn: "马达加斯加",
    phone_code: 261,
    icon: MG,
  },
  {
    country_code: "IM",
    country_en: "Острів Мен",
    country_cn: "马恩岛",
    phone_code: 44,
    icon: IM,
  },
  {
    country_code: "MV",
    country_en: "Мальдіви",
    country_cn: "马尔代夫",
    phone_code: 960,
    icon: MV,
  },
  {
    country_code: "MT",
    country_en: "Мальта",
    country_cn: "马耳他",
    phone_code: 356,
    icon: MT,
  },
  {
    country_code: "MW",
    country_en: "Малаві",
    country_cn: "马拉维",
    phone_code: 265,
    icon: MW,
  },
  {
    country_code: "MY",
    country_en: "Малайзія",
    country_cn: "马来西亚",
    phone_code: 60,
    icon: MY,
  },
  {
    country_code: "ML",
    country_en: "Малі",
    country_cn: "马里",
    phone_code: 223,
    icon: ML,
  },
  {
    country_code: "MK",
    country_en: "Македонія",
    country_cn: "马其顿",
    phone_code: 389,
    icon: MK,
  },
  {
    country_code: "MH",
    country_en: "Маршаллові Острови",
    country_cn: "马绍尔群岛",
    phone_code: 692,
    icon: MH,
  },
  {
    country_code: "MQ",
    country_en: "Мартиніка",
    country_cn: "马提尼克",
    phone_code: 596,
    icon: MQ,
  },
  {
    country_code: "YT",
    country_en: "Майотта",
    country_cn: "马约特",
    phone_code: 262,
    icon: YT,
  },
  {
    country_code: "MU",
    country_en: "Маврикій",
    country_cn: "毛里求斯",
    phone_code: 230,
    icon: MU,
  },
  {
    country_code: "MR",
    country_en: "Мавританія",
    country_cn: "毛里塔尼亚",
    phone_code: 222,
    icon: MR,
  },
  {
    country_code: "US",
    country_en: "Сполучені Штати Америки",
    country_cn: "美国",
    phone_code: 1,
    icon: US,
  },
  {
    country_code: "AS",
    country_en: "Американське Самоа",
    country_cn: "美属萨摩亚",
    phone_code: 1684,
    icon: AS,
  },
  {
    country_code: "MN",
    country_en: "Монголія",
    country_cn: "蒙古",
    phone_code: 976,
    icon: MN,
  },
  {
    country_code: "MS",
    country_en: "Монтсеррат",
    country_cn: "蒙特塞拉特",
    phone_code: 1664,
    icon: MS,
  },
  {
    country_code: "BD",
    country_en: "Бангладеш",
    country_cn: "孟加拉国",
    phone_code: 880,
    icon: BD,
  },
  {
    country_code: "PE",
    country_en: "Перу",
    country_cn: "秘鲁",
    phone_code: 51,
    icon: PE,
  },
  {
    country_code: "MM",
    country_en: "М'янма",
    country_cn: "缅甸",
    phone_code: 95,
    icon: MM,
  },
  {
    country_code: "MD",
    country_en: "Молдова",
    country_cn: "摩尔多瓦",
    phone_code: 373,
    icon: MD,
  },
  {
    country_code: "MA",
    country_en: "Марокко",
    country_cn: "摩洛哥",
    phone_code: 212,
    icon: MA,
  },
  {
    country_code: "MC",
    country_en: "Монако",
    country_cn: "摩纳哥",
    phone_code: 377,
    icon: MC,
  },
  {
    country_code: "MZ",
    country_en: "Мозамбік",
    country_cn: "莫桑比克",
    phone_code: 258,
    icon: MZ,
  },
  {
    country_code: "MX",
    country_en: "Мексика",
    country_cn: "墨西哥",
    phone_code: 52,
    icon: MX,
  },
  {
    country_code: "NA",
    country_en: "Намібія",
    country_cn: "纳米比亚",
    phone_code: 264,
    icon: NA,
  },
  {
    country_code: "ZA",
    country_en: "Південна Африка",
    country_cn: "南非",
    phone_code: 27,
    icon: ZA,
  },
  {
    country_code: "SS",
    country_en: "Південний Судан",
    country_cn: "南苏丹",
    phone_code: 211,
    icon: SS,
  },
  {
    country_code: "NR",
    country_en: "Науру",
    country_cn: "瑙鲁",
    phone_code: 674,
    icon: NR,
  },
  {
    country_code: "NI",
    country_en: "Нікарагуа",
    country_cn: "尼加拉瓜",
    phone_code: 505,
    icon: NI,
  },
  {
    country_code: "NP",
    country_en: "Непал",
    country_cn: "尼泊尔",
    phone_code: 977,
    icon: NP,
  },
  {
    country_code: "NE",
    country_en: "Нігер",
    country_cn: "尼日尔",
    phone_code: 227,
    icon: NE,
  },
  {
    country_code: "NG",
    country_en: "Нігерія",
    country_cn: "尼日利亚",
    phone_code: 234,
    icon: NG,
  },
  {
    country_code: "NU",
    country_en: "Ніуе",
    country_cn: "纽埃",
    phone_code: 683,
    icon: NU,
  },
  {
    country_code: "NO",
    country_en: "Норвегія",
    country_cn: "挪威",
    phone_code: 47,
    icon: NO,
  },
  {
    country_code: "NF",
    country_en: "Острів Норфолк",
    country_cn: "诺福克群岛",
    phone_code: 672,
    icon: NF,
  },
  {
    country_code: "PT",
    country_en: "Португалія",
    country_cn: "葡萄牙",
    phone_code: 351,
    icon: PT,
  },
  {
    country_code: "JP",
    country_en: "Японія",
    country_cn: "日本",
    phone_code: 81,
    icon: JP,
  },
  {
    country_code: "SE",
    country_en: "Швеція",
    country_cn: "瑞典",
    phone_code: 46,
    icon: SE,
  },
  {
    country_code: "CH",
    country_en: "Швейцарія",
    country_cn: "瑞士",
    phone_code: 41,
    icon: CH,
  },
  {
    country_code: "SV",
    country_en: "Сальвадор",
    country_cn: "萨尔瓦多",
    phone_code: 503,
    icon: SV,
  },
  {
    country_code: "WS",
    country_en: "Самоа",
    country_cn: "萨摩亚",
    phone_code: 685,
    icon: WS,
  },
  {
    country_code: "RS",
    country_en: "Сербія",
    country_cn: "塞尔维亚",
    phone_code: 381,
    icon: RS,
  },
  {
    country_code: "SL",
    country_en: "Сьєрра-Леоне",
    country_cn: "塞拉利昂",
    phone_code: 232,
    icon: SL,
  },
  {
    country_code: "SN",
    country_en: "Сенегал",
    country_cn: "塞内加尔",
    phone_code: 221,
    icon: SN,
  },
  {
    country_code: "CY",
    country_en: "Кіпр",
    country_cn: "塞浦路斯",
    phone_code: 357,
    icon: CY,
  },
  {
    country_code: "SC",
    country_en: "Сейшельські Острови",
    country_cn: "塞舌尔",
    phone_code: 248,
    icon: SC,
  },
  {
    country_code: "SA",
    country_en: "Саудівська Аравія",
    country_cn: "沙特阿拉伯",
    phone_code: 966,
    icon: SA,
  },
  {
    country_code: "ST",
    country_en: "Сан-Томе і Принсіпі",
    country_cn: "圣多美和普林西比民主共和国",
    phone_code: 239,
    icon: ST,
  },
  {
    country_code: "SH",
    country_en: "Острови Святої Єлени",
    country_cn: "圣赫勒拿",
    phone_code: 290,
    icon: SH,
  },
  {
    country_code: "KN",
    country_en: "Сент-Кіттс і Невіс",
    country_cn: "圣基茨和尼维斯",
    phone_code: 1869,
    icon: KN,
  },
  {
    country_code: "LC",
    country_en: "Сент-Люсія",
    country_cn: "圣卢西亚",
    phone_code: 1758,
    icon: LC,
  },
  {
    country_code: "MF",
    country_en: "Сен-Мартен",
    country_cn: "法属圣马丁",
    phone_code: 590,
    icon: MF,
  },
  {
    country_code: "SM",
    country_en: "Сан-Марино",
    country_cn: "圣马力诺",
    phone_code: 378,
    icon: SM,
  },
  {
    country_code: "VC",
    country_en: "Сент-Вінсент і Гренадини",
    country_cn: "圣文森特和格林纳丁斯",
    phone_code: 1784,
    icon: VC,
  },
  {
    country_code: "LK",
    country_en: "Шрі-Ланка",
    country_cn: "斯里兰卡",
    phone_code: 94,
    icon: LK,
  },
  {
    country_code: "SK",
    country_en: "Словаччина",
    country_cn: "斯洛伐克",
    phone_code: 421,
    icon: SK,
  },
  {
    country_code: "SI",
    country_en: "Словенія",
    country_cn: "斯洛文尼亚",
    phone_code: 386,
    icon: SI,
  },
  {
    country_code: "SD",
    country_en: "Судан",
    country_cn: "苏丹",
    phone_code: 249,
    icon: SD,
  },
  {
    country_code: "SR",
    country_en: "Суринам",
    country_cn: "苏里南",
    phone_code: 597,
    icon: SR,
  },
  {
    country_code: "SB",
    country_en: "Соломонові Острови",
    country_cn: "所罗门群岛",
    phone_code: 677,
    icon: SB,
  },
  {
    country_code: "SO",
    country_en: "Сомалі",
    country_cn: "索马里",
    phone_code: 252,
    icon: SO,
  },
  {
    country_code: "TJ",
    country_en: "Таджикистан",
    country_cn: "塔吉克斯坦",
    phone_code: 992,
    icon: TJ,
  },
  {
    country_code: "TW",
    country_en: "Тайвань",
    country_cn: "台湾",
    phone_code: 886,
    icon: TW,
  },
  {
    country_code: "TH",
    country_en: "Таїланд",
    country_cn: "泰国",
    phone_code: 66,
    icon: TH,
  },
  {
    country_code: "TZ",
    country_en: "Танзанія",
    country_cn: "坦桑尼亚",
    phone_code: 255,
    icon: TZ,
  },
  {
    country_code: "TO",
    country_en: "Тонга",
    country_cn: "汤加",
    phone_code: 676,
    icon: TO,
  },
  {
    country_code: "TC",
    country_en: "Теркс і Кайкос",
    country_cn: "特克斯和凯科斯群岛",
    phone_code: 1649,
    icon: TC,
  },
  {
    country_code: "TT",
    country_en: "Тринідад і Тобаго",
    country_cn: "特立尼达和多巴哥",
    phone_code: 1868,
    icon: TT,
  },
  {
    country_code: "TN",
    country_en: "Туніс",
    country_cn: "突尼斯",
    phone_code: 216,
    icon: TN,
  },
  {
    country_code: "TV",
    country_en: "Тувалу",
    country_cn: "图瓦卢",
    phone_code: 688,
    icon: TV,
  },
  {
    country_code: "TR",
    country_en: "Туреччина",
    country_cn: "土耳其",
    phone_code: 90,
    icon: TR,
  },
  {
    country_code: "TM",
    country_en: "Туркменістан",
    country_cn: "土库曼斯坦",
    phone_code: 993,
    icon: TM,
  },
  {
    country_code: "TK",
    country_en: "Токелау",
    country_cn: "托克劳",
    phone_code: 690,
    icon: TK,
  },
  {
    country_code: "WF",
    country_en: "Волліс і Футуна",
    country_cn: "瓦利斯和富图纳",
    phone_code: 681,
    icon: WF,
  },
  {
    country_code: "VU",
    country_en: "Вануату",
    country_cn: "瓦努阿图",
    phone_code: 678,
    icon: VU,
  },
  {
    country_code: "GT",
    country_en: "Гватемала",
    country_cn: "危地马拉",
    phone_code: 502,
    icon: GT,
  },
  {
    country_code: "VE",
    country_en: "Венесуела",
    country_cn: "委内瑞拉",
    phone_code: 58,
    icon: VE,
  },
  {
    country_code: "BN",
    country_en: "Бруней",
    country_cn: "文莱",
    phone_code: 673,
    icon: BN,
  },
  {
    country_code: "UG",
    country_en: "Уганда",
    country_cn: "乌干达",
    phone_code: 256,
    icon: UG,
  },
  {
    country_code: "UA",
    country_en: "Україна",
    country_cn: "乌克兰",
    phone_code: 380,
    icon: UA,
  },
  {
    country_code: "UY",
    country_en: "Уругвай",
    country_cn: "乌拉圭",
    phone_code: 598,
    icon: UY,
  },
  {
    country_code: "UZ",
    country_en: "Узбекистан",
    country_cn: "乌兹别克斯坦",
    phone_code: 998,
    icon: UZ,
  },
  {
    country_code: "GR",
    country_en: "Греція",
    country_cn: "希腊",
    phone_code: 30,
    icon: GR,
  },
  {
    country_code: "ES",
    country_en: "Іспанія",
    country_cn: "西班牙",
    phone_code: 34,
    icon: ES,
  },
  {
    country_code: "EH",
    country_en: "Західна Сахара",
    country_cn: "西撒哈拉",
    phone_code: 212,
    icon: EH,
  },
  {
    country_code: "HK",
    country_en: "Гонконг",
    country_cn: "中国香港特别行政区",
    phone_code: 852,
    icon: HK,
  },
  {
    country_code: "SG",
    country_en: "Сингапур",
    country_cn: "新加坡",
    phone_code: 65,
    icon: SG,
  },
  {
    country_code: "NC",
    country_en: "Нова Каледонія",
    country_cn: "新喀里多尼亚",
    phone_code: 687,
    icon: NC,
  },
  {
    country_code: "NZ",
    country_en: "Нова Зеландія",
    country_cn: "新西兰",
    phone_code: 64,
    icon: NZ,
  },
  {
    country_code: "HU",
    country_en: "Угорщина",
    country_cn: "匈牙利",
    phone_code: 36,
    icon: HU,
  },
  {
    country_code: "SY",
    country_en: "Сирія",
    country_cn: "叙利亚",
    phone_code: 963,
    icon: SY,
  },
  {
    country_code: "JM",
    country_en: "Ямайка",
    country_cn: "牙买加",
    phone_code: 1876,
    icon: JM,
  },
  {
    country_code: "AM",
    country_en: "Арменія",
    country_cn: "亚美尼亚",
    phone_code: 374,
    icon: AM,
  },
  {
    country_code: "YE",
    country_en: "Ємен",
    country_cn: "也门",
    phone_code: 967,
    icon: YE,
  },
  {
    country_code: "IQ",
    country_en: "Ірак",
    country_cn: "伊拉克",
    phone_code: 964,
    icon: IQ,
  },
  {
    country_code: "IR",
    country_en: "Іран",
    country_cn: "伊朗",
    phone_code: 98,
    icon: IR,
  },
  {
    country_code: "IL",
    country_en: "Ізраїль",
    country_cn: "以色列",
    phone_code: 972,
    icon: IL,
  },
  {
    country_code: "IT",
    country_en: "Італія",
    country_cn: "意大利",
    phone_code: 39,
    icon: IT,
  },
  {
    country_code: "IN",
    country_en: "Індія",
    country_cn: "印度",
    phone_code: 91,
    icon: IN,
  },
  {
    country_code: "ID",
    country_en: "Індонезія",
    country_cn: "印度尼西亚",
    phone_code: 62,
    icon: ID,
  },
  {
    country_code: "GB",
    country_en: "Велика Британія",
    country_cn: "英国",
    phone_code: 44,
    icon: GB,
  },
  {
    country_code: "VG",
    country_en: "Британські Віргіни",
    country_cn: "英属维京群岛",
    phone_code: 1284,
    icon: VG,
  },
  {
    country_code: "JO",
    country_en: "Йорданія",
    country_cn: "约旦",
    phone_code: 962,
    icon: JO,
  },
  {
    country_code: "VN",
    country_en: "В'єтнам",
    country_cn: "越南",
    phone_code: 84,
    icon: VN,
  },
  {
    country_code: "ZM",
    country_en: "Замбія",
    country_cn: "赞比亚",
    phone_code: 260,
    icon: ZM,
  },
  {
    country_code: "JE",
    country_en: "Джерсі",
    country_cn: "泽西岛",
    phone_code: 44,
    icon: JE,
  },
  {
    country_code: "TD",
    country_en: "Чад",
    country_cn: "乍得",
    phone_code: 235,
    icon: TD,
  },
  {
    country_code: "GI",
    country_en: "Гібралтар",
    country_cn: "直布罗陀",
    phone_code: 350,
    icon: GI,
  },
  {
    country_code: "CL",
    country_en: "Чилі",
    country_cn: "智利",
    phone_code: 56,
    icon: CL,
  },
  {
    country_code: "CF",
    country_en: "Центральноафриканська Республіка",
    country_cn: "中非共和国",
    phone_code: 236,
    icon: CF,
  },
  {
    country_code: "CN",
    country_en: "Китай",
    country_cn: "中国",
    phone_code: 86,
    icon: CN,
  },
].sort((c, b) => c.country_en.localeCompare(b.country_en));

export default phonecodes;
