import { useEffect, useRef, useState } from "react";
import IMask from "imask";
import "./index.scss";
import FlagsSelect from "./FlagSelect";
import classNames from "classnames";
const PhoneInput = ({
  placeholder,
  getValue,
}: {
  placeholder: string;
  getValue: (value: string) => void;
}) => {
  const [active, setActive] = useState(false);
  const mask = useRef<any>(null);
  const ref = useRef<any>(null);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState({
    phone_code: 38,
    country_code: "UA",
  });
  useEffect(() => {
    if (!ref.current) return;
    const maskOptions = {
      mask: `+${country?.phone_code} (000) 000-00-0[0]`,
      lazy: !active,
    };
    mask.current = IMask(ref.current, maskOptions)
      .on("accept", () => {
        setPhone(mask.current.value);

        mask.current.updateValue();
      })
      .on("complete", () => {
        // setPhone(mask.current.value)
        getValue(mask.current.value);
        mask.current.updateValue();
      });

    return () => {
      mask.current.destroy();
    };
  }, [active, phone, country]);

  useEffect(() => {
    getValue(phone);
  }, [phone]);

  useEffect(() => {
    setPhone("");
  }, [country]);

  return (
    <div className={classNames("phone-input", active && "focused")}>
      <FlagsSelect
        currentValue={country}
        setValue={(value: any) =>
          setCountry({
            ...country,
            ...value,
          })
        }
      />
      <input
        onFocus={() => setActive(true)}
        onBlur={() => {
          setActive(false);
        }}
        autoComplete="off"
        ref={ref}
        className="phone-input__input"
        value={phone}
        placeholder={"+" + country?.phone_code.toString() || ""} //placeholder
        onChange={(e) => {
          setPhone(e.target.value);
          mask.current.updateValue();
        }}
      />
    </div>
  );
};

export default PhoneInput;
