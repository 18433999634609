import { useEffect, useState } from 'react'
import Button from '../common/Button'
import './index.scss'
import Lottie from 'react-lottie'
import json from '../../lottie/cottle.json'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'

const Formats = observer(() => {
  const content = GlobalState.content?.formats
  const [isLoop, setLoop] = useState(true)
  const [isPlay, setPlay] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const formats = document.querySelector('.formats')
    if (!formats) return

    var observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((e) => {
          if (e.isIntersecting) {
            setPlay(true)
            setTimeout(() => {
              setLoop(false)
              setPlay(false)
            }, 3000)
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      },
    )
    observer.observe(formats)

    return () => {
      observer.unobserve(formats)
    }
  }, [content])

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [content])

  if (!content) return <></>
  return (
    <section className="formats">
      <div className="formats__container">
        <div className="formats__col">
          <div
            className="formats__img wow animated fadeInUp"
            data-wow-duration="1.25s"
          >
            <Lottie
              options={{
                loop: isLoop,
                autoplay: isPlay,
                animationData: json,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled={false}
              isPaused={!isPlay}
            />
          </div>
          <div
            className="formats__subtitle wow animated fadeInUp"
            data-wow-delay="0.35s"
          >
            {content.subtitle}
          </div>
          <div
            className="formats__title wow animated fadeInUp"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
          >
            {content.title}
          </div>
          <div
            className="formats__text wow animated fadeInUp"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
            dangerouslySetInnerHTML={{ __html: content.text }}
          ></div>
          {width > 480 && (
            <Button
              text={content.button}
              click={() => {
                const cost = document.querySelector('.cost')
                if (!cost) return

                const costRect = cost.getBoundingClientRect().top,
                  bodyRect = document
                    .querySelector('body')!
                    .getBoundingClientRect().top
                const offset = costRect - bodyRect

                window.scrollTo({
                  top: offset,
                  behavior: 'smooth',
                })
              }}
            />
          )}
        </div>
        <div className="formats__list">
          {content.list.map((c: any, i: number) => (
            <div
              className="formats__item wow animated fadeInUp"
              key={i}
              data-wow-duration="1.25s"
              data-wow-delay="0.5s"
            >
              <div className="formats__item-title">{c.title}</div>
              <div className="formats__item-text">{c.text}</div>
            </div>
          ))}
        </div>
        {width <= 480 && (
          <Button
            text={content.button}
            click={() => {
              const cost = document.querySelector('.cost')
              if (!cost) return

              const costRect = cost.getBoundingClientRect().top,
                bodyRect = document
                  .querySelector('body')!
                  .getBoundingClientRect().top
              const offset = costRect - bodyRect

              window.scrollTo({
                top: offset,
                behavior: 'smooth',
              })
            }}
          />
        )}
      </div>
    </section>
  )
})

export default Formats
