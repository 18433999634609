const scrollFunc = () => {
  if (window.location.href.includes('#')) {
    const idblock = window.location.hash

    if (idblock) {
      const block = document.querySelector(idblock)
      if (block) {
        block.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }
}

export default scrollFunc
