import './index.scss'
import Slider from 'react-slick'
import { ReactComponent as Arrow } from '../../assets/chevron.svg'
import imgBlock from '../../assets/reviews/Frame (6).svg'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Button from '../common/Button'
import GlobalState, { setReview } from '../../stores/GlobalState'
import { observer } from 'mobx-react'
const Reviews = observer(() => {
  const [currentSlide, setSlide] = useState(0)
  const ref = useRef<any>(null)
  const [width, setWidth] = useState(window.innerWidth)
  const content = GlobalState.content?.reviews

  const getReviews = () => {
    let res: any = [],
      arr: any = []

    content.list.forEach((l: any, i: number) => {
      arr.push(l)
      if (!((i + 1) % 2)) {
        res.push(arr)
        arr = []
      }
    })

    return res
  }
  const settings = {
    dots: false,
    arrows: width > 768 ? false : true,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div className="reviews__btn back wow animated fadeInUp">
        <Arrow />
      </div>
    ),
    prevArrow: (
      <div className="reviews__btn next wow animated fadeInUp">
        <Arrow />
      </div>
    ),
  }

  useEffect(() => {
    const countSymbols = width > 1200 ? 365 : 250
    const items = document.querySelectorAll('.reviews__item-block')
    if (!items) return
    Array.from(items as any)?.forEach((element: any, i: number) => {
      const spans = (element as HTMLElement).querySelectorAll('span')
      if (!spans) return

      const inner = content.list[i].text
        .replaceAll('<span>', '')
        .replaceAll('</span>', '')
      if (!inner) return
      spans[0].textContent =
        inner.length > countSymbols
          ? inner?.slice(0, countSymbols) + '...'
          : inner
    })
  }, [width, content])

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  }, [])

  useEffect(() => {
    if (!content) return
    document.querySelectorAll('.reviews__item-more').forEach((e, i) => {
      e?.addEventListener('click', () => {
        setReview(content.list[i])
      })
    })
  }, [content])

  if (!content) return <></>

  return (
    <section className="reviews">
      <div className="reviews__container">
        <div className="reviews__top">
          <div className="reviews__title wow animated fadeInUp">
            {content.title}
          </div>
          {width > 768 && (
            <div className="reviews__btns">
              <div
                className={classNames(
                  'reviews__btn back wow animated fadeInUp',
                  !currentSlide && 'disabled-bt',
                )}
                onClick={() => ref.current.slickPrev()}
              >
                <Arrow />
              </div>
              <div
                className={classNames(
                  'reviews__btn next wow animated fadeInUp',
                  currentSlide + 1 == getReviews().length && 'disabled-bt',
                )}
                onClick={() => ref.current.slickNext()}
              >
                <Arrow />
              </div>
            </div>
          )}
        </div>
        <div
          className="reviews__list wow animated fadeInUp"
          data-wow-delay="0.15s"
        >
          {width > 768 && (
            <Slider
              {...settings}
              ref={ref}
              afterChange={(index:number) => setSlide(index)}
            >
              {getReviews().map((li: any, i: number) => (
                <div className="reviews__item" key={i}>
                  {li.map((l: any, id: number) => (
                    <div className="reviews__item-block" key={id}>
                      <div className="reviews__item-row">
                        <div className="reviews__item-img">
                          <img src={l.src} alt="design.kitchen" />
                        </div>
                        <div className="reviews__item-col">
                          <div className="reviews__item-title">{l.title}</div>
                          <div className="reviews__item-info">
                            <div
                              className="reviews__item-text"
                              dangerouslySetInnerHTML={{
                                __html:
                                  l.text +
                                  `<span class="reviews__item-more">
                            ${content.showMore}
                          </span>`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="reviews__item-socials">
                        {l.socials.map((s: any, ind: number) => (
                          <a href={s.link} key={ind} target='__blank'>
                            <img src={s.icon} alt="design.kitchen" />
                          </a>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </Slider>
          )}
          {width <= 768 && (
            <Slider
              {...settings}
              ref={ref}
              afterChange={(index:number) => setSlide(index)}
            >
              {content.list.map((l: any, id: number) => (
                <div className="reviews__item" key={id}>
                  <div className="reviews__item-block">
                    <div className="reviews__item-row">
                      <div className="reviews__item-img">
                        <img src={l.src} alt="design.kitchen" />
                      </div>
                      <div className="reviews__item-col">
                        <div className="reviews__item-title">{l.title}</div>
                        <div className="reviews__item-info">
                          <div
                            className="reviews__item-text"
                            dangerouslySetInnerHTML={{
                              __html:
                                l.text +
                                `<span class="reviews__item-more">
                            ${content.showMore}
                          </span>`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="reviews__item-socials">
                      {l.socials.map((s: any, ind: number) => (
                        <a href={s.link} key={ind} target="__blank">
                          <img src={s.icon} alt="design.kitchen" />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="reviews__bottom">
          <Button
            text={content.buttonTitle}
            click={() => window.open(content.buttonLink, '__blank')}
          />
          <div className="reviews__img">
            <img src={imgBlock} alt="design.kitchen" />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Reviews
