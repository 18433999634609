import './index.scss'

import Button from '../common/Button'
import imgblock from '../../assets/teacher-icon.svg'
import { observer } from 'mobx-react'
import GlobalState from '../../stores/GlobalState'
import Lottie from 'react-lottie'
import json from '../../lottie/eyes.json'
import { useEffect } from 'react'


const Teachers = observer(() => {
  const content = GlobalState.content?.teachers

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  if (!content) return <></>
  return (
    <section className="teachers" id="teachers">
      <div className="teachers__container">
        <div className="teachers__top">
          {GlobalState.width <= 900 && (
            <div className="teachers__img wow animated fadeIn">
              {' '}
              <Lottie options={defaultOptions} />
            </div>
          )}
          <div className="teachers__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="teachers__text  wow animated fadeInUp">
            {content.text}
            <span>
              {content.awwards
                .trim()
                .split(',')
                .map((a: any, i: number) => (
                  <a href={content.hrefs[i]} target="__blank" key={i}>
                    {a}
                    {i != content.awwards.trim().split(',').length - 1
                      ? ', '
                      : ''}
                  </a>
                ))}
            </span>
          </div>
          {GlobalState.width > 900 && (
            <div className="teachers__img  wow animated fadeIn">
              <Lottie options={defaultOptions} />
            </div>
          )}
        </div>
        <div className="teachers__list">
          {content.list.map((li: any, i: number) => (
            <div
              className="teachers__item  wow animated fadeInUp"
              key={i}
              data-wow-duration="1s"
              data-wow-delay={`${0.15 + 0.15 * i}s`}
            >
              <div className="teachers__item-img">
                <img src={li.img} alt="design.kitchen" />
              </div>
              <div className="teachers__item-info">
                <div className="teachers__item-title">{li.title}</div>
                <div
                  className="teachers__item-text"
                  dangerouslySetInnerHTML={{ __html: li.text }}
                ></div>
                {li.socials.length ? (
                  <div className="teachers__item-socials">
                    {li.socials.map((si: any, i: number) => (
                      <a href={si.link} key={i} target="__blank">
                        <img src={si.icon} alt-="design.kitchen" />
                      </a>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
        {/* <div
          className="teachers__item white-bg   wow animated fadeInUp"
          data-wow-duration="1s"
          data-wow-delay={`${0.15 * content.list.length + 0.15}s`}
        ></div>
        <div
          className="teachers__item orange-bg  wow animated fadeInUp"
          data-wow-duration="1s"
          data-wow-delay={`${0.3 + 0.15 * content.list.length + 0.15}s`}
        ></div> */}
        <div className="teachers__bottom">
          <div className="teachers__col">
            <div className="teachers__subtitle  wow animated fadeInUp">
              {content.subtitle}
            </div>
            <div className="teachers__text  wow animated fadeInUp">
              {content.subtext}
            </div>
            <Button
              click={() => (window.location.href = `mailto:${content.email}`)}
              text={content.email}
            />
          </div>
          <div className="teachers__bottom-img  wow animated fadeInUp">
            <img src={imgblock} alt="design.kitchen" />
          </div>
        </div>
      </div>
    </section>
  )
})

export default Teachers
