import './index.scss'
import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { ReactComponent as Phone } from '../../../assets/phone.svg'
import { ReactComponent as Telegram } from '../../../assets/telegram.svg'
import { useLocation, useNavigate } from 'react-router'
import Navigation from '../Navigation'
import LangPicker from '../LangPicker'
import { observer } from 'mobx-react'
import GlobalState from '../../../stores/GlobalState'
const Header = observer(() => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const content = GlobalState.content?.header
  if (!content) return <></>
  return (
    <header className="header">
      {pathname?.split('/')?.pop()?.trim() == '' && (
        <div className="header__top">{content.top}</div>
      )}
      <div className="header__container">
        <Logo className="header__logo" onClick={() => navigate('/')} />
        {pathname?.split('/')?.pop()?.trim() == '' && <Navigation />}
        <div className="header__func">
          {/* <LangPicker /> */}
          <a href={`tel:${content.phone}`} className="header__item">
            <div className="header__item-icon">
              <Phone />
            </div>
            <span>{content.phone}</span>
          </a>
          <a
            href={`https://t.me/${content.telegram.replaceAll('@', '')}`}
            className="header__item"
            target={'__blank'}
          >
            <div className="header__item-icon">
              <Telegram />
            </div>
            <span> {content.telegram}</span>
          </a>
        </div>
      </div>
    </header>
  )
})

export default Header
