import './index.scss'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'
import { useEffect } from 'react'

const Results = observer(() => {
  const content = GlobalState.content?.results

  if (!content) return <></>
  return (
    <section className="results">
      <div className="results__container">
        <div className="results__title">{content.title}</div>
        <div className="results__list ">
          {content.list.map((li: any, i: number) => (
            <div
              className="results__item wow animated fadeInUp"
              key={i}
              data-wow-delay={`${!i || (i + 1) % 2 ? 0.15 : 0}s`}
            >
              <div
                className="results__item-img wow animated zoomIn"
                data-wow-duration="0.5s"
                data-wow-delay={`${0.15 + 0.15 * i}s`}
              >
                <img src={li.icon} alt="design.kitchen" />
              </div>
              <div className="results__item-col">
                <div className="results__item-title">{li.title}</div>
                <div className="results__item-text">{li.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})

export default Results
