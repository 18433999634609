import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useEffect } from "react";
import Header from "../components/common/Header";
import ContactContent from "../components/ContactContent";
import GlobalState, { setContent } from "../stores/GlobalState";

const Contact = observer(() => {
  useEffect(() => {
    if (localStorage.getItem("card")) {
      runInAction(() => {
        GlobalState.cost = JSON.parse(localStorage.getItem("card")!);
      });
    }
  }, []);

  useEffect(() => {
    setContent();
    document.querySelector("html")?.classList.add("contact-page");
  }, []);
  return (
    <>
      <Header />
      <ContactContent />
    </>
  );
});

export default Contact;
