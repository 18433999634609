import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import './index.scss'

const Button = ({ text, click }: { text: string; click: () => void }) => {
  const ref = useRef<any>(null)
  useEffect(() => {
    if (!ref.current) return
    ;['mouseenter', 'mouseout'].forEach((evt) => {
      ref.current.addEventListener(evt, (e: any) => {
        let parentOffset = ref.current.getBoundingClientRect(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top - window.scrollY

        const span = ref.current.getElementsByTagName('span')

        span[0].style.top = relY + 'px'
        span[0].style.left = relX + 'px'
      })
    })
  }, [])
  return (
    <div
      className={classNames('button wow animated fadeInUp')}
      onClick={click}
      ref={ref}
      data-wow-duration="1.25s"
      data-wow-delay="0.5s"
    >
      <span className="button-hover"></span>
      <span className="button-text"> {text}</span>
    </div>
  )
}
export default Button
