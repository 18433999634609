import { observer } from 'mobx-react'
import GlobalState from '../../../stores/GlobalState'
import './index.scss'
const Navigation = observer(() => {
  const menu = GlobalState.content?.menu

  if (!menu) return <></>
  return (
    <nav className="navigation">
      <div className="navigation__list">
        {menu.map((m: any, i: any) => (
          <a className="navigation__item" key={i} href={`#${m.link}`}>
            {m.title}
          </a>
        ))}
      </div>
    </nav>
  )
})

export default Navigation
