import './index.scss'
import { useEffect } from 'react'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'
const Companies = observer(() => {
  const content = GlobalState.content?.companies

  if (!content) return <></>
  return (
    <section className="companies">
      <div className="companies__container">
        <div className="companies__top">
          <div className="companies__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="companies__text wow animated fadeInUp">
            {content.text}
          </div>
        </div>
        <div className="companies__list">
          {content.list.map((l: any, i: number) => (
            <div
              className="companies__item wow animated zoomIn"
              key={i}
              data-wow-delay={`${0.15 * i}s`}
            >
              <img src={l} alt="design.kitchen" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})
export default Companies
