import { observer } from "mobx-react";
import { useEffect } from "react";
import Header from "../components/common/Header";
import ThanksContent from "../components/ThankContent";
import { setContent } from "../stores/GlobalState";

const Thanks = observer(() => {
  useEffect(() => {
    setContent();
  }, []);
  return (
    <>
      <Header />
      <ThanksContent />
    </>
  );
});

export default Thanks;
