import { useEffect, useState } from 'react'
import Button from '../common/Button'
import PhoneInput from '../common/PhoneInput'
import './index.scss'
import img from '../../assets/form/Group.svg'
import GlobalState, { send } from '../../stores/GlobalState'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react'

const MainForm = observer(() => {
  const navigate = useNavigate()
  const content = GlobalState.content?.form

  const [state, setState] = useState({
    name: '',
    phone: '',
    email: '',
  })

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
  })

  const isEmail = (email: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )
  }

  const submit = () => {
    const phone = state.phone
      .replaceAll('+', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
    
    const errs = {
      name: !state.name.length ? content.nameError : '',
      phone:
        !state.phone.length || state.phone.includes('_')
          ? content.phoneError
          : '',
      email:
        !state.email.length || !isEmail(state.email) ? content.emailError : '',
    }
    if (errs.name.length || errs.phone.length || errs.email.length) {
      setErrors(errs)
      const er = [errs.name, errs.phone, errs.email]
      const inputs = document.querySelectorAll('.main-form__label')
      inputs.forEach((i, id) => {
        ;(i as HTMLElement).style.setProperty(
          '--input-error',
          JSON.stringify(`${er[id]}`),
        )
      })
      return
    } else {
      setErrors(errs)
    }

    send(state).then(() => {
      navigate('/thanks-marathon')
    })
  }

  useEffect(() => {
    if (errors.email.length || errors.name.length || errors.phone.length) {
      const errs = {
        name: !state.name.length ? content.nameError : '',
        phone:
          !state.phone.length || state.phone.includes('_')
            ? content.phoneError
            : '',
        email:
          !state.email.length || !isEmail(state.email)
            ? content.emailError
            : '',
      }

      setErrors(errs)
    }
  }, [state])

  if (!content) return <></>

  return (
    <section className="main-form">
      <div className="main-form__container">
        <div className="main-form__col">
          <div
            className="main-form__subtitle wow fadeInUp animated"
            data-wow-delay="0.35s"
          >
            {content.subtitle}
          </div>
          <div
            className="main-form__title wow fadeInUp animated"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
          >
            {content.title}
          </div>
          <div
            className="main-form__text wow fadeInUp animated"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
          >
            {content.text}
          </div>
          <div
            className="wow fadeInUp animated"
            data-wow-duration="1.25s"
            data-wow-delay="0.35s"
            style={{ width: '100%' }}
          >
            <div
              className={classNames(
                'main-form__label',
                errors.name.length && 'error',
              )}
            >
              <input
                className="input"
                value={state.name}
                placeholder={content.namePlaceholder}
                onChange={(e) => setState({ ...state, name: e.target.value })}
              />
            </div>
            <div
              className={classNames(
                'main-form__label',
                errors.phone.length && 'error',
              )}
            >
              <PhoneInput
                placeholder={content.phonePlaceholder}
                getValue={(value) => setState({ ...state, phone: value })}
              />
            </div>
            <div
              className={classNames(
                'main-form__label',
                errors.email.length && 'error',
              )}
            >
              <input
                type={'email'}
                className="input"
                value={state.email}
                placeholder={content.emailPlaceholder}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </div>
            <Button text={content.button} click={submit} />
          </div>
        </div>
        <div
          className="main-form__img wow  fadeInUp animated"
          data-wow-duration="1.25s"
          data-wow-delay="0.35s"
        >
          <img src={img} alt="design.kitchen" />
        </div>
      </div>
    </section>
  )
})

export default MainForm
