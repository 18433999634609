import { observable, runInAction } from "mobx";
import money from "../assets/money.svg";
import laptop from "../assets/Group 1304516420.svg";
import note from "../assets/Frame 1304516421.svg";
import be from "../assets/socials/Asset 329 4.svg";
import fb from "../assets/socials/Asset 326 4.svg";
import insta from "../assets/socials/Asset 328 3.svg";
import { ReactComponent as Icon2 } from "../assets/prefer/02.svg";
import { ReactComponent as Icon3 } from "../assets/prefer/03.svg";
import { ReactComponent as Icon4 } from "../assets/prefer/04.svg";
import { ReactComponent as Icon1 } from "../assets/prefer/05.svg";
import dribble from "../assets/socials/dribbble 1.svg";
import im1 from "../assets/teachers/Lecturer card.png";
import im2 from "../assets/teachers/Lecturer card (1).png";
import im3 from "../assets/teachers/Lecturer card (2).png";
import im4 from "../assets/teachers/Lecturer card (3).png";
import im5 from "../assets/teachers/Lecturer card (4).png";
import im6 from "../assets/teachers/Lecturer card (5).png";
import im7 from "../assets/teachers/Lecturer card (6).png";
import im8 from "../assets/teachers/Lecturer card (7).png";
import im9 from "../assets/teachers/Lecturer card (8).png";

import imgp from "../assets/portfolio/Mask Group.png";
import imgp1 from "../assets/portfolio/Mask Group (1).png";
import imgp2 from "../assets/portfolio/Mask Group (2).png";
import imgp3 from "../assets/portfolio/Mask Group (3).png";
import imgp4 from "../assets/portfolio/Mask Group (4).png";
import imgp5 from "../assets/portfolio/image 11.png";

import imgc from "../assets/companies/1.svg";
import imgc1 from "../assets/companies/2.svg";
import imgc2 from "../assets/companies/3.svg";
import imgc5 from "../assets/companies/4.svg";
import imgc4 from "../assets/companies/5.svg";
import imgc3 from "../assets/companies/6.svg";
import imgc6 from "../assets/companies/7.svg";
import imgc7 from "../assets/companies/EPAM.svg";

import ic1 from "../assets/results/1.svg";
import ic2 from "../assets/results/2.svg";
import ic3 from "../assets/results/3.svg";
import ic4 from "../assets/results/4.svg";

import ima from "../assets/awwards/1.png";
import ima1 from "../assets/awwards/2.png";
import ima2 from "../assets/awwards/3.png";
import ima3 from "../assets/awwards/4.png";
import ima4 from "../assets/awwards/5.png";

import phone from "../assets/phone.svg";
import email from "../assets/socials/Email.svg";
import telegram from "../assets/telegram.svg";

import icont1 from "../assets/contact/1.svg";
import icont2 from "../assets/contact/2.svg";
import icont3 from "../assets/contact/3.svg";

import st1avat from "../assets/reviews/st1/ns-1.jpg";
import st1por1 from "../assets/reviews/st1/np-1.jpg";
import st1por2 from "../assets/reviews/st1/np-2.jpg";

import st2avat from "../assets/reviews/st2/ns-2.jpg";
import st2por1 from "../assets/reviews/st2/np-3.jpg";
import st2por2 from "../assets/reviews/st2/np-4.jpg";

import st3avat from "../assets/reviews/st3/ns-3.jpg";
import st3por1 from "../assets/reviews/st3/np-5.jpg";
import st3por2 from "../assets/reviews/st3/np-6.jpg";

import st4avat from "../assets/reviews/st4/ns-4.jpg";
import st4por1 from "../assets/reviews/st4/np-7.jpg";
import st4por2 from "../assets/reviews/st4/np-8.jpg";

import st5avat from "../assets/reviews/st5/ns-5.jpg";
import st5por1 from "../assets/reviews/st5/np-9.jpg";
import st5por2 from "../assets/reviews/st5/np-10.jpg";

import st6avat from "../assets/reviews/st6/ns-6.jpg";
import st6por1 from "../assets/reviews/st6/np-11.jpg";
import st6por2 from "../assets/reviews/st6/np-12.jpg";

const GlobalState: any = observable({
  isPopShow: false,
  width: window.innerWidth,
  review: null,
  cost: null,
  content: null,
});

export const changePopState = () => {
  runInAction(() => {
    GlobalState.isPopShow = !GlobalState.isPopShow;
  });
};

export const setContent = () => {
  fetch("content.json")
    .then((res) => res.json())
    .then((result) => {

      runInAction(() => {
        GlobalState.content = result;
      });
    });
  // runInAction(() => {
  //   //     GlobalState.content = {
  //   //       header: {
  //   //         top: 'Внимание! Русский военный корабль. Ты сам все знаешь 🚢',
  //   //         phone: '+38 050 300 88 90',
  //   //         telegram: '@dk_school',
  //   //       },
  //   //       menu: [
  //   //         {
  //   //           title: 'Про курс',
  //   //           link: 'course',
  //   //         },
  //   //         {
  //   //           title: 'Програма',
  //   //           link: 'program',
  //   //         },
  //   //         {
  //   //           title: 'Викладачі ',
  //   //           link: 'teachers',
  //   //         },
  //   //         {
  //   //           title: 'Портфоліо',
  //   //           link: 'portfolio',
  //   //         },
  //   //         {
  //   //           title: 'Вартість',
  //   //           link: 'cost',
  //   //         },
  //   //       ],
  //   //       intro: {
  //   //         subtitle: 'Продовжуємо набір на курс, що стартує 21 листопада',
  //   //         title: 'Веб-дизайн<br/> з нуля',
  //   //         text:
  //   //           'Спеціалізована школа з UX/UI дизайну (web and mobile) від практикуючих дизайнерів.',
  //   //         placeholder: 'Твій номер телефону',
  //   //         button: 'Записатися на курс',
  //   //         list: [
  //   //           {
  //   //             title: 'обирай свій: 3 формати навчання ',
  //   //             icon: laptop,
  //   //           },
  //   //           {
  //   //             title: 'більш, ніж  50 годин матеріалів',
  //   //             icon: note,
  //   //           },
  //   //           {
  //   //             title: 'від 6000 гривень за курс',
  //   //             icon: money,
  //   //           },
  //   //         ],
  //   //         socials: [
  //   //           {
  //   //             link:
  //   //               'https://www.facebook.com/Design-Kitchen-школа-веб-дизайна-113605820001156/',
  //   //             icon: fb,
  //   //           },
  //   //           {
  //   //             link: 'https://www.behance.net/designkitchenschool',
  //   //             icon: be,
  //   //           },
  //   //           {
  //   //             link: 'https://www.instagram.com/designkitchen.school/',
  //   //             icon: insta,
  //   //           },
  //   //         ],
  //   //         error: 'Некоректний номер',
  //   //       },
  //   //       formats: {
  //   //         subtitle: 'Як ми робимо зі студентів дизайнерів?',
  //   //         title: '3 оптимальні формати участі',
  //   //         text: `<p>Ти можеш підібрати для себе зручний формат навчання, зважаючи на твою мету і час, який ти готовий приділити для її досягнення. Всі формати включають освоєння інструменту Figma, основ композиції, типографіки, знайомлять тебе з дизайн-процесами та методологіями проектування інтерфейсів. І, звичайно, ти обов’язково навчишя створювати інтерактивні прототипи.<p/> `,
  //   //         button: 'Порівняти формати',
  //   //         list: [
  //   //           {
  //   //             title: 'Отримуєш доступ у особистий кабінет ',
  //   //             text:
  //   //               'Одразу після оплати ти потрапляєш до особистого кабінету на нашій заточеній під зручне навчання платформі з усіма відеолекціями, скринкастами, PDF-матеріалами та домашніми завданнями.',
  //   //           },
  //   //           {
  //   //             title: 'Покроково освоюєш програму',
  //   //             text:
  //   //               'Освоюєш нові інструменти і процеси упродовж 30 структурованих відеоуроків та 12 корисних скрінкастів. У кожному модулі буде трохи теорії та практичних кейсів від лектора, будуть персональні практичні та домашні завдання.',
  //   //           },
  //   //           {
  //   //             title: 'Прокачуєш скіли і отримуєш сертифікат ',
  //   //             text:
  //   //               'За умови виконання усіх домашніх завдань, кожен учасник курсу наприкінці отримує електронний сертифікат (якщо треба - надрукуємо, підпишемо і надішлемо :)). ',
  //   //           },
  //   //         ],
  //   //       },
  //   //       form: {
  //   //         subtitle: 'Хочеш переконатись, що це — твоє?',
  //   //         title: 'Приєднуйся до нашого триденного безкоштовного марафону',
  //   //         text:
  //   //           'Заповни форму, щоб відкрити доступ до трьох пробних занять курсу «Веб-дизайнер з нуля» безкоштовно. Після надсилання форми ти отримаєш лист на пошту з доступом до уроків.',
  //   //         phonePlaceholder: 'Твій номер телефону в телеграмі',
  //   //         namePlaceholder: 'Твоє ім’я',
  //   //         emailPlaceholder: 'Твій email',
  //   //         button: 'Почати вчитися',
  //   //         nameError: "Некоректне ім'я",
  //   //         phoneError: 'Некоректний номер',
  //   //         emailError: 'Некоректний email',
  //   //       },
  //   //       prefer: {
  //   //         title: 'Кому підійде наш курс?',
  //   //         text:
  //   //           'Курс орієнтований на тих, хто налаштований отримати професійні навички і готовий докласти до цього зусиль. ',
  //   //         list: [
  //   //           {
  //   //             title: 'Тим, хто хоче потрапити в IT',
  //   //             text:
  //   //               'Цей курс для тих, хто хоче потрапити у стабільну сферу - одну з небагатьох, яку не дуже змінили навіть теперішні події у світі. ',
  //   //             icon: <Icon1 />,
  //   //           },
  //   //           {
  //   //             title: 'Графічним дизайнерам',
  //   //             text: `Дизайнерам, які хочуть почати розвиватись у web-напрямку.`,
  //   //             icon: <Icon2 />,
  //   //           },
  //   //           {
  //   //             title: 'Самоучкам',
  //   //             text: `Тим, хто хоче заповнити прогалини та структурувати свої знання вебдизайну.`,
  //   //             icon: <Icon3 />,
  //   //           },
  //   //           {
  //   //             title: 'Вічним Почекунам',
  //   //             text:
  //   //               'Тим, хто ніяк не наважиться на зміни і чекає на “той чамий час”. Цей час настав.',
  //   //             icon: <Icon4 />,
  //   //           },
  //   //         ],
  //   //       },
  //   //       program: {
  //   //         title: 'З чого складається курс?',
  //   //         list: [
  //   //           {
  //   //             title: 'Вивчення інструмента Figma',
  //   //             text: `Навчишся вправлятися з Figma як Кристіану Роналду з м’ячем. Такі терміни як smart animate, auto layout, components і atomic design перестануть бути для тебе незрозумілими. За 4 заняття викладачі поділяться лайхаками та фішками про те, як суттєво прискорити свою роботу у Figma.`,
  //   //           },
  //   //           {
  //   //             title: 'Типографіка',
  //   //             text: `50% інформації, яку ми отримуємо з інтерфейсів, передається через літери. Тому ми приділили аж чотири заняття вивченню всіх тонкощів роботи з типографікою. Ти навчишся відрізняти кернінг від трекінгу, красиво верстати тексти, а також підбирати шрифти, які будуть передавати потрібний настрій.`,
  //   //           },
  //   //           {
  //   //             title: 'Композиція',
  //   //             text: `Як розмістити елементи в інтерфейсі? Як маніпулювати увагою користувача, щоб привернути його увагу? Способи створення ідеальної композиції, золотий перетин, правило внутрішнього та зовнішнього та багато іншого.`,
  //   //           },
  //   //           {
  //   //             title: 'Колористика',
  //   //             text: `Як колір впливає на сприйняття? Де брати натхнення та як підібрати кольори для проекту? Чим відрізняється преміальний чорний колір від чорного похоронного? Ця і інша потрібна для дизайнера інформація буде в цій частині курсу. `,
  //   //           },
  //   //           {
  //   //             title: 'Landing page',
  //   //             text: `Що це таке? Як створити структуру такої сторінки та чим це наповнювати? Правило AIDA.. Створення лендінгу відповідно до Workflow, що генерить лідів. Ну і, звісно, плюс один проект в твоє портфоліо.`,
  //   //           },
  //   //           {
  //   //             title: 'Дизайн-процес',
  //   //             text: `Занурення в усі етапи роботи над проектом: збір даних та аналіз, робота з референсами, створення wireframe, дизайн-концепт, прототип. Ти створиш свій проект у відповідності до визначеного workflow.`,
  //   //           },
  //   //           {
  //   //             title: 'Адаптивний дизайн та сітки',
  //   //             text: `У чому відмінність mobile version, responsive design та adaptive design? Навіщо потрібний адаптивний дизайн? Особливості Bootstrap, створення адаптивного дизайну та mobile first.`,
  //   //           },
  //   //           {
  //   //             title: 'UX-методології і Benchmarking',
  //   //             text: `Навчимо, як проводити Competitor analysis. Спробуємо метод персон та методологію JTBD. Покажемо, що таке Job stories та User stories і у чому принципова різниця. Поговоримо про кейси, які вплинули на конверсію.`,
  //   //           },
  //   //           {
  //   //             title: 'Як зробити красиво',
  //   //             text: `З яких елементів складається сайт? Як зробити, щоб вони виглядали так, щоб в замовника текла слинка? Де шукати, як підбирати чи створювати візуальний контент для своїх проектів?`,
  //   //           },
  //   //           {
  //   //             title: 'Культура ведення макетів',
  //   //             text: `Як підготувати проект до верстки, щоб потім не було пекельно боляче. Практичне заняття зі створення UI kit. Даємо чек-лист найпоширеніших помилок дизайнерів-початківців.`,
  //   //           },
  //   //           {
  //   //             title: 'Soft skills',
  //   //             text: `У 85% дизайнерів, навіть середи тих, котрі вже навіть працюють, ці навички відсутні. Проте вони вкрай важливі для роботи, та й для повсякденного життя. Познайомся з переліком найважливіших Soft skills і отримай рекомендації як їх розвинути, щоб увійти до 10% кращих дизайнерів на ринку праці.`,
  //   //           },
  //   //           {
  //   //             title: 'Як дизайнеру знайти роботу',
  //   //             text: `Як не облажатися на співбесіді? Що має бути в CV і що категорично не варто включати до резюме?`,
  //   //           },
  //   //           {
  //   //             title: 'Мистецтво створення портфоліо',
  //   //             text: `Як створити портфоліо, яке б приваблювало і клієнтів, і роботодавців? Створення соковитого кейсу на Behance. Просування аккаунту в Dribbble.
  //   // `,
  //   //           },
  //   //           {
  //   //             title: 'Чи є життя після курсу?',
  //   //             text: `Де шукати клієнтів? Огляд майданчиків та каналів для пошуку замовлень, а також рекомендації щодо роботи з ними.
  //   // `,
  //   //           },
  //   //           {
  //   //             title: 'Створення та презентація курсового проекту',
  //   //             text: `Створення курсового проекту на визначену тему. Студенти, допущені до захисту курсового проекту, продемонстрирують, що вони вивчили на кусі та прокачають свої навички презентації проектів. Ну і плюс один кейс в твоє портфоліо
  //   // `,
  //   //           },
  //   //           {
  //   //             title: 'Дизайн-батл',
  //   //             text: `Справжній хардкор та симулятор першого робочого дня. У тебе буде 2,5 години на створення дизайну на таємну тему. Переможці будуть опубліковані на сторінці найкращих випускників Design Kitchen.
  //   // `,
  //   //           },
  //   //         ],
  //   //       },
  //   //       teachers: {
  //   //         title: 'У нас викладають лише практикуючі дизайнери',
  //   //         text:
  //   //           'У наших викладачів 4+ років досвіду, взаємодія з клієнтами з усього світу і досконале портфоліо. Їхні проекти відзначені міжнародними нагородами: ',
  //   //         awwards: 'Awwwwards, CSS design awards, FWA.',
  //   //         hrefs: [
  //   //           'https://www.awwwards.com/sites/design-kitchen-school',
  //   //           'https://www.cssdesignawards.com/sites/design-kitchen-school/40568/',
  //   //           'https://thefwa.com/',
  //   //         ],
  //   //         list: [
  //   //           {
  //   //             title: 'Сергій Левчун',
  //   //             text: `<p>UI-дизайнер at Astound Commerce.</p>
  //   // <p>5+ років в дизайні. Основний напрямок діяльності: інтернет-магазини. За цей час працював над проектами Puma, Ferrari, Alibaba. Випустив 60+ студентів. </p>`,
  //   //             img: im1,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/levchun',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://dribbble.com/levchun',
  //   //                 icon: dribble,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Кирилл Важинский',
  //   //             text: `<p>Product Designer at Equal agency. </p>
  //   // <p>Спеціалізується на Saas-платформах та мобільних додатках. Працював із клієнтами з Великобританії, США, ОАЕ, Нідерландів. Практикуючий ментор дизайнерів-початківців. </p>`,
  //   //             img: im2,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/kirilvazhinskiy',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://www.instagram.com/youngsaint.k/',
  //   //                 icon: insta,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Максим Богун',
  //   //             text: `<p>UX/UI Designer at Equal Agency. </p>
  //   // <p>4+ років досвіду в дизайні, Top Rated Plus на Upwork. Основний напрямок діяльності: eCommerce і SaaS платформи. Практикуючий ментор дизайнерів-початківців.  </p>`,
  //   //             img: im3,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/bogun',
  //   //                 icon: be,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Юлія В’юнник',
  //   //             text: `<p>Product Designer at Equal agency. </p>
  //   // <p>Провідні напрямки: FinTech, EdTech. Проектує інтерфейси для SaaS та мобільних додатків для ринку Європи та Америки. Практикуючий ментор дизайнерів-початківців. </p>`,
  //   //             img: im4,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/Julia_Viunnik',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://instagram.com/julia_viunnyk',
  //   //                 icon: insta,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Сергій Губа',
  //   //             text: `<p>Founder & Art director at Equal agency. </p>
  //   // <p> Випустив 100+ студентів. Має 7+ років досвіду в web. Працював з Esprit, Disney, Canon, IFS. Top Rated plus на Upwork.</p>`,
  //   //             img: im5,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/SergioGuba',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://dribbble.com/SergioGuba',
  //   //                 icon: dribble,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Катерина Подзолкіна',
  //   //             text: `<p>Product Designer at Equal agency </p>
  //   // <p>3+ роки у дизайні. Має міжнародні нагороди CSSDA та Awwwards. Провідний напрям – Mobile Apps. Top Rated на UpWork. Практикуючий ментор дизайнерів-початківців.
  //   // </p>`,
  //   //             img: im6,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/heykatie',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link:
  //   //                   'https://instagram.com/podzolkina.kate?igshid=YmMyMTA2M2Y=',
  //   //                 icon: insta,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Дмитро Малєєв',
  //   //             text: `<p> Head of design at Equal agency. </p>
  //   // <p> 5+ років в дизайні. Розробляв стартапи та продукти для клієнтів зі США, Великобританії та ОАЕ як Product designer, Artdirector та Product manager.</p>`,
  //   //             img: im7,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/dmitrymale0a50',
  //   //                 icon: be,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Єгор Криклій',
  //   //             text: `<p>Product Designer at Equal agency.</p>
  //   // <p>  4+ років роботи в дизайні. Спеціалізується на веб та мобільних додатках: SaaS, B2B2C, eCommerce для ринку Канади та ОАЕ. Top Rated plus на Upwork. Практикуючий ментор дизайнерів-початківців.</p>`,
  //   //             img: im8,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://www.behance.net/yehorkrykl0b63',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://www.instagram.com/yehor.kryklii/',
  //   //                 icon: insta,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             title: 'Юрій Функендорф',
  //   //             text: `
  //   //           <p>Owner Cre8 Team Design Studio.</p>
  //   // <p>6+ років у дизайні. Створює мобільні програми, eCommerce, eLearning, SaaS, B2B2C-платформи. Top Rated на UpWork.</p>`,
  //   //             img: im9,
  //   //             socials: [
  //   //               {
  //   //                 link: 'https://dribbble.com/funkendorf',
  //   //                 icon: dribble,
  //   //               },
  //   //               {
  //   //                 link: 'https://www.behance.net/funkendorf',
  //   //                 icon: be,
  //   //               },
  //   //               {
  //   //                 link: 'https://www.instagram.com/cre8.team.ds/',
  //   //                 icon: insta,
  //   //               },
  //   //             ],
  //   //           },
  //   //         ],
  //   //         subtitle: 'Хочеш приєднатися до команди викладачів?',
  //   //         subtext:
  //   //           'Ми відкриті до співпраці. Якщо ти крутий дизайнер, ілюстратор, художник і ти хочеш ділитися своїми знаннями та досвідом — скоріше пиши нам на пошту:',
  //   //         email: 'hello@designkitchen.com.ua',
  //   //       },
  //   //       portfolio: {
  //   //         title: 'Роботи наших студентів',
  //   //         text:
  //   //           'Що може бути кращим доказом якості освіти, як не роботи випускників? А деякі з них відзначені дизайн-нагородами на платформі Behance. ',
  //   //         numbers: [
  //   //           {
  //   //             count: '200+',
  //   //             subtitle: 'студентів',
  //   //             text:
  //   //               'За 5 років існування школи ми випустили більш, ніж 200 студентів',
  //   //           },
  //   //           {
  //   //             count: '80%',
  //   //             subtitle: 'працевлаштовано',
  //   //             text: 'Ми усіляко сприяємо працевлаштуванню наших студентів',
  //   //           },
  //   //         ],
  //   //         buttonTitle: 'Більше робіт на Behance',
  //   //         buttonLink: 'https://www.behance.net/designkitchenschool',
  //   //         list: [
  //   //           {
  //   //             icon: imgp,
  //   //             link:
  //   //               'https://www.behance.net/gallery/139333729/Black-Limi-redesign-concept',
  //   //           },
  //   //           {
  //   //             icon: imgp1,
  //   //             link:
  //   //               'https://www.behance.net/gallery/79616801/F-Lovers-Concept-Portfolio-Website',
  //   //           },
  //   //           {
  //   //             icon: imgp2,
  //   //             link:
  //   //               'https://www.behance.net/gallery/150123033/OHUENO-E-commerce-Redesign-Website-Clothing-Store',
  //   //           },
  //   //           {
  //   //             icon: imgp3,
  //   //             link:
  //   //               'https://www.behance.net/gallery/67688883/Herman-Miller-redesign-concept',
  //   //           },
  //   //           {
  //   //             icon: imgp4,
  //   //             link:
  //   //               'https://www.behance.net/gallery/136011643/PROPRO-furniture-store-redesign-concept',
  //   //           },
  //   //           {
  //   //             icon: imgp5,
  //   //             link:
  //   //               'https://www.behance.net/gallery/78162933/Fitbit-UXUI-redesign-concept',
  //   //           },
  //   //         ],
  //   //       },
  //   //       reviews: {
  //   //         title: 'Відгуки випускників',
  //   //         showMore: 'Детальніше',
  //   //         list: [
  //   //           {
  //   //             maintitle:
  //   //               'Велике спасибі Design Kitchen School, за те, що надихаєте та мотивуєте',
  //   //             title: 'Альбіна Колосова',
  //   //             text: `<span>Я давно спостерігала за Design Kitchen School. Довго горіла думкою піти вчитися до них. І все-таки, одного разу побачивши набір у новий гурт, я зважилася. </span>
  //   // <span>Я багато дізналася нового, дуже була залучена до навчання. Після курсу, я спалахнула ще більше розвиватися в веб-дизайні. Дякуємо великому Design Kitchen School, за те, що надихаєте і мотивуєте розвиватися далі 😊 </span>`,
  //   //             subtitle: 'UI/UX designer',
  //   //             src: st1avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: insta,
  //   //                 link: 'https://www.instagram.com/albina_bri?r=nametag',
  //   //               },
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/anibley',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link: 'https://dribbble.com/shots/15128289-FILA-Redesign',
  //   //                 img: st1por1,
  //   //               },
  //   //               {
  //   //                 link: 'https://dribbble.com/shots/11585109-FILA-Page-404',
  //   //                 img: st1por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             maintitle: 'Поповнив свій дизайн портфоліо гідними роботами',
  //   //             title: 'Дмитро Сергішкін',
  //   //             text: `<span>Пройшов навчання у школі Design Kitchen, залишився задоволений отриманими знаннями та можу сміливо її рекомендувати тим, хто планує розпочати свій шлях або розвиватися у сфері UX/UI Design. Грамотна подача матеріалу, якісний feedback від викладача, багато корисної інформації, основний акцент на практичному застосуванні отриманих знань, що помітно виділяє на тлі інших шкіл. </span>
  //   // <span>У школі проводяться Design Battles, навчання передбачає курсову та дипломну роботу, що дає можливість попрацювати з реальними замовниками та реальними проектами вже в процесі навчання. Після закінчення школи завдяки насиченій програмі можна поповнити свій дизайн портфоліо гідними роботами, оскільки вони проходять строгу перевірку великого кола практикуючих дизайнерів. Бути випускником школи дизайну Design Kitchen – це гарний старт для успішної кар'єри. </span>`,
  //   //             subtitle: 'UI/UX designer, 26 років',
  //   //             src: st3avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: insta,
  //   //                 link: 'https://www.instagram.com/design_sdy',
  //   //               },
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/Sergushkin_Dmitry',
  //   //               },
  //   //               {
  //   //                 icon: be,
  //   //                 link: 'https://www.behance.net/sergushkin',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15044869-Saas-Dashboard-Account-Dark-theme',
  //   //                 img: st3por1,
  //   //               },
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15139799-SaaS-Finance-App-Responsive-Dashboard-UI-UX-Design',
  //   //                 img: st3por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             maintitle: 'Цікаві лекції та презентації з класним матеріалом',
  //   //             title: 'Вікторія Наришкіна',
  //   //             text: `<span>Я проходила курс з ui/ux дизайну у Сергія Губи. Курс дуже сподобався, все доступно пояснюється цікаві лекції та презентації з класним матеріалом та доречними жартами 👍🏻 </span>
  //   // <span>Дуже тішить що навіть зараз я можу переглянути старі лекції, якщо потрібно собі про щось нагадати. 😄 </span>`,
  //   //             subtitle: 'UI/UX designer',
  //   //             src: st2avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: insta,
  //   //                 link: 'https://instagram.com/vitoria_naryshkina',
  //   //               },
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/VikaSti',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link: 'https://dribbble.com/shots/14914151-DataOx',
  //   //                 img: st2por1,
  //   //               },
  //   //               {
  //   //                 link: 'https://dribbble.com/shots/12113293-Game',
  //   //                 img: st2por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             maintitle: 'До курсів я не знала, чим хотіла б займатися у житті',
  //   //             title: 'Катерина Слєпньова',
  //   //             text: `<span>До курсів я не знала чим хотіла б займатися в житті. Подобався дизайн, комп'ютери і, взагалі, # яумамипрограміст, пішла на курси для пошуку себе. З перших занять я закохалася в дизайн, викладач (Сережа Губа) дуже швидко дав зрозуміти, що легко не буде, але результат того коштував. На середині курсу я вже сміялася зі своїх робіт, зроблених до курсу і купила новий монітор, то ще тоді зрозуміла, що мене затягнуло в дизайн надовго. </span>
  //   // <span>Домашні завдання, курсові та диплом – кожне було новим випробуванням. За 3 місяці мій скилл злетів, а найголовніше, я нарешті зрозуміла, НІЖ я хочу займатися в житті. Курси і сам Сергій за такий короткий час - прищепили мені дуже важливі якості: уважність, усидливість і бажання робити більше і йти до більшого. І ось я вже більше двох років займаюся дизайном, мені це дуже подобається, і це приносить мені моральне і матеріальне задоволення😌 </span>`,
  //   //             subtitle: 'UI/UX designer, 23 років',
  //   //             src: st4avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: insta,
  //   //                 link: 'https://www.instagram.com/katarina_designer/',
  //   //               },
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/katyaslepp',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link: 'https://dribbble.com/shots/14686454-Fitness-App',
  //   //                 img: st4por1,
  //   //               },
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15489749-Pizzeria-Landing-Page',
  //   //                 img: st4por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             maintitle:
  //   //               'Полюбив дизайн усією душею і намагаюся вдосконалюватися у цій справі щодня',
  //   //             title: 'Михайло Дуплякін',
  //   //             text: `<span>Після курсів у Design Kitchen залишилися позитивні враження! Я познайомився з великою кількістю цікавих та залучених у свою справу людей. </span>
  //   // <span>Завдяки двом класним викладачам – Олегу та Сергію, я полюбив дизайн всією душею і намагаюся вдосконалюватися у цій справі щодня. </span>`,
  //   //             subtitle:
  //   //               'Senior Mobile Designer at Rush Street Interactive, 23 роки',
  //   //             src: st5avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/mishadupliakin',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15482688-Crypto-wallet-Mobile-App',
  //   //                 img: st5por1,
  //   //               },
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15822493-Meditation-Mobile-App',
  //   //                 img: st5por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //           {
  //   //             maintitle: 'Працюю в Cre8 Team',
  //   //             title: "Ситник Дар'я",
  //   //             text: `<span>До Design Kitchen я була самоукою, але мені не вистачало структурованої теоретичної бази та інтенсивної практики. Все це і навіть більше я отримала тут. </span>
  //   // <span>Атмосфера групи була дуже мотивуюча і надихаюча, що тільки посилювало бажання вчитися. Спасибі моїм чудовим викладачам Юлі Маслік та Жені Костянтиновій за знання та підтримку) Загалом я дуже рада, що обрала цю школу і вже не раз радила її своїм знайомим. </span>`,
  //   //             subtitle: 'UI/UX designer at Crea8 team, 26 років ',
  //   //             src: st6avat,
  //   //             socials: [
  //   //               {
  //   //                 icon: dribble,
  //   //                 link: 'https://dribbble.com/Daria_Sytnyk',
  //   //               },
  //   //               {
  //   //                 icon: insta,
  //   //                 link: 'https://www.instagram.com/dari.kapusta',
  //   //               },
  //   //             ],
  //   //             works: [
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15169729-Play-by-Heart-Mobile-first-web-app-for-musicians',
  //   //                 img: st6por1,
  //   //               },
  //   //               {
  //   //                 link:
  //   //                   'https://dribbble.com/shots/15197403-SPIRIT-Ethnic-jewelry-online-shop-concept',
  //   //                 img: st6por2,
  //   //               },
  //   //             ],
  //   //           },
  //   //         ],
  //   //         buttonTitle: 'Більше відгуків тут',
  //   //         buttonLink:
  //   //           'https://www.google.com/search?q=design+kitchen+school+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B&rlz=1C5CHFA_enUA983UA983&sxsrf=ALiCzsZCwqtAVNokaCFfhIR6zglSCt4ooA%3A1660827218748&ei=Ujb-YuyhLZiIur4P5NuUyAs&ved=0ahUKEwjskqDDt9D5AhUYhM4BHeQtBbkQ4dUDCA4&uact=5&oq=design+kitchen+school+%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgAQ6BAgAEEc6BAgjECc6BggAEB4QFjoJCAAQHhDJAxAWOgUIIRCgAToICCEQHhAWEB1KBAhBGABKBAhGGABQuAJYghlgihpoAnACeACAAdgBiAG_C5IBBTAuNi4ymAEAoAEByAEIwAEB&sclient=gws-wiz-serp#lrd=0x4127a149532120ef:0x66ec4b3488d88e73,1',
  //   //       },
  //   //       companies: {
  //   //         title: 'Компанії, в яких працюють наші студенти',
  //   //         text:
  //   //           'Також деякі з наших випускників працюють викладачами в інших школах. А найсміливіші відкривають власні студії дизайну.',
  //   //         list: [imgc1, imgc2, imgc6, imgc4, imgc3, imgc5, imgc, imgc7],
  //   //       },
  //   //       results: {
  //   //         title: 'Що ти отримаєш в фіналі?',
  //   //         list: [
  //   //           {
  //   //             title: '+5 робіт у портфоліо',
  //   //             text:
  //   //               'Не важливо, це будуть твої перші чи не перші проекти у портфоліо. Головне, що це буде 5 твоїх самостійних робіт, які поповнять твоє портфоліо і які можна буде продемонструвати потенційним клієнтам чи роботодавцю. ',
  //   //             icon: ic1,
  //   //           },
  //   //           {
  //   //             title: 'Сертифікат',
  //   //             text:
  //   //               'Здавалось би, кому потрібен цей папірець? А потім в гугл-формі, яку просить заповнити потенційний роботодавець, з’являєься рядочок “прикріпіть сюди свої сертифікати”. То от саме для цього. Ну і можна додати до CV і запостити в Linkedin.',
  //   //             icon: ic2,
  //   //           },
  //   //           {
  //   //             title: 'Практика, практика і ще трішки практики',
  //   //             text:
  //   //               'Це найголовніший принцип і, разом із тим, головний секрет успішності. Практична частина дасть тобі можливість зануритись у робочі процеси дизайнера, а наші лекції, завдання і допомога менторів підготують тебе до реальної роботи. ',
  //   //             icon: ic3,
  //   //           },
  //   //           {
  //   //             title: 'Допомога з працевлаштуванням',
  //   //             text:
  //   //               'Ми хочемо пишатись тобою і собою, ми хочемо мати право сказати: “Ми така крута школа, що наші студенти працевлаштовуються і стають успішними після нашого курсу”. Тому ми всіляко допомагаємо колегам з ІТ-компаній у пошуку кадрів: рекомендуємо наших студентів і всіляко сприємо появі “job offer”',
  //   //             icon: ic4,
  //   //           },
  //   //         ],
  //   //       },
  //   //       cost: {
  //   //         title: 'Вартість навчання ',
  //   //         text: 'Обери для себе найоптимальніший формат навчання ',
  //   //         allCourse: '/ весь курс',
  //   //         subtext: 'ціна діє на період бойових дій в Україні',
  //   //         buttonTitle: 'Обрати',
  //   //         list: [
  //   //           {
  //   //             subtitle: 'Для тих, хто хоче розпочати просто зараз',
  //   //             subtext: null,
  //   //             title: 'Старт',
  //   //             oldPrice: `<span>₴10 000</span> звичайна ціна`,
  //   //             newPrice: '₴6000',
  //   //             link: 'https://pay.fondy.eu/s/9xMpV',
  //   //             possibility: [
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //             ],
  //   //           },
  //   //           {
  //   //             subtitle: 'Для тих, хто любить ставити питання',
  //   //             subtext: null,
  //   //             title: 'Груповий',
  //   //             oldPrice: `<span>₴18 000</span> звичайна ціна`,
  //   //             newPrice: '₴11 000',
  //   //             link: null,
  //   //             possibility: [
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               false,
  //   //               false,
  //   //               false,
  //   //             ],
  //   //           },
  //   //           {
  //   //             subtitle: 'Максимум практики',
  //   //             title: 'Індивідуальний',
  //   //             subtext: 'Вільно 2 із 3 місць',
  //   //             oldPrice: `<span>₴25 000</span> звичайна ціна`,
  //   //             newPrice: '₴16 000',
  //   //             link: null,
  //   //             possibility: [
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //               true,
  //   //             ],
  //   //           },
  //   //         ],
  //   //         possibility: [
  //   //           'Доступ до 30 відеолекцій',
  //   //           'Доступ до 12 практичних занять у вигляді скринкастів',
  //   //           'Додаткові корисні матеріали (статті/чек-листи/шаблони)',
  //   //           'Доступ до закритого чату в Telegram ',
  //   //           'Live Q&A-сесії і розбори домашніх завдань з менторами',
  //   //           "Обов'язкове виконання домашніх завдань",
  //   //           'Сертифікат після закінчення навчання',
  //   //           'Створення та презентація курсового проекту',
  //   //           'Доступ до закритого Telegram-чату випускників ',
  //   //           'Постійна персональна менторська підтримка куратора курсу',
  //   //           'Перевірка та фідбек за всіма домашніми завданнями',
  //   //           'Участь у дизайн-батлі',
  //   //         ],
  //   //       },
  //   //       awwards: {
  //   //         title: 'А ти знаєш, що наш сайт має мiжнародні нагороди?',
  //   //         text:
  //   //           'Ми отримали нагороду Specal Kudos на <a href = "https://www.cssdesignawards.com/sites/design-kitchen-school/40568/" target="__blank">CSS design Awards</a> та Honorable Mention на  <a target="__blank" href = "https://www.awwwards.com/sites/design-kitchen-school">Awwwards</a>. Так, це ми хвалимось. ',
  //   //         list: [ima, ima1, ima2, ima3, ima4],
  //   //       },
  //   //       subscribe: {
  //   //         title: 'Курс SKILL UP марафон за $0',
  //   //         subtitle: 'Подаруночки для випускників',
  //   //         text:
  //   //           'Усім випускникам курсу «Веб дизайн з нуля» ми надаємо доступ до інтенсиву SKILL UP за $75 (закреслено) $0. Прокачай свої знання у маркетингу та продажах, працевлаштуванні, ціноутворенні, NFT та крипто мистецтві.',
  //   //         placeholder: 'Твій номер телефону',
  //   //         buttonTitle: 'Подати заявку на курс',
  //   //         error: 'Некоректний номер',
  //   //       },
  //   //       faq: {
  //   //         title: 'F.A.Q.',
  //   //         list: [
  //   //           {
  //   //             title: 'Який ноутбук потрібний для навчання?',
  //   //             text: `<span>Для навчання підійде ноутбук на Windows чи Mac os — не має значення. Якщо ти плануєш покупку, то рекомендуємо вибирати ноутбук із такими параметрами: роздільна здатність екрану 1920х1080 пікселів, матриця IPS, розмір 15 дюймів, процесор i5, пам'ять 8gb. Також дуже рекомендуємо ставити одразу ssd диск на 128gb. На ноутбуці з такими параметрами робота буде у радість.</span>`,
  //   //           },
  //   //           {
  //   //             title: 'Куди кидати гроші за курс?',
  //   //             text: `<span>Для того, щоб оплатити участь, обери відповідний формат навчання. Тариф «Старт» можна оплатити через сервіс безпечної оплати просто на нашому сайті. Для цього в розділі «Вартість навчання» просто натисни кнопку “вибрати” і у тебе з'явиться можливість оплатити навчання. І вже через добу після оплати у тебе з'явиться доступ до матеріалів.</span>
  //   // <span>У форматах «Груповий» та «Індивідуальний» обмежена кількість місць та наявна дата старту. Оплата відбувається на розрахунковий рахунок за реквізитами після оформлення заявки та узгодження деталей з менеджером курсу. Щоб зареєструватися на курс, перейди до розділу «вартість навчання», обери відповідний тариф і заповни форму.</span>
  //   // `,
  //   //           },
  //   //           {
  //   //             title: 'Як відбувається навчання?',
  //   //             text: `<span>Базовий курс складається з 30 лекційних занять та 12 практичних занять у записі. Далі, залежно від обраного формату навчання, ти отримуєш різну кількість опцій, практичних завдань та кількість фідбека від кураторів.</span>
  //   // <span>Якщо ти хочеш отримати структуровану базу, розібратися в основах, проте у тебе немає часу на виконання домашніх завдань і можливості підлаштовуватися під графік занять, тобі підійде перший, формат “Старт“. Легкий старт, щоб зрозуміти чи підходить тобі професія UI/UX дизайнера.</span>
  //   // <span>“Груповий“ формат навчання прив'язаний до дати старту групи. Раз на тиждень у тебе буде можливість отримати відповіді на запитання щодо матеріалів занять, виконувати домашні завдання та підготувати свою першу роботу для портфоліо. Сесія питання-відповідь буде проходити в Zoom, запис буде збережено і ти зможеш переглянути його в будь-який зручний час.</span>
  //   // <span>”Індивідуальний” формат — це про максимальну підтримку куратора та додаткову практику. У тебе буде свій чат з ментором, в якому ти зможеш в будь-який час ставити запитання, а також отримати фідбек за всіма домашніми завданнями та курсовим проектом. Цей формат підходить для швидкого старту у професії та дає найвищі шанси на працевлаштування одразу після курсу. Кількість місць у цьому форматі навчання обмежена.</span>
  //   // `,
  //   //           },
  //   //           {
  //   //             title: 'Чи підійде мені курс, якщо я не маю досвіду і я новачок?',
  //   //             text: `<span>За 5 років існування наша школа випустила понад 230 студентів, 80% з яких до навчання у нас не мали жодних навичок UI/UX дизайну, а зараз успішно працюють, організували власні дизайн-студії і, навіть, самі вже стали менторами та наставниками. Однак дуже важливо розуміти, що результат курсу безпосередньо залежить від рівня твоєї залученості та особистої вмотивованості. Зі свого боку ми зробимо все, щоб цей курс дав потужний старт у новій професії.</span>`,
  //   //           },
  //   //           {
  //   //             title: 'Хто веде курс?',
  //   //             text: `<span>Наша школа працює на базі дизайн-студії Equal. Ми — команда практикуючих дизайнерів, які працюють над проектами з усього світу. Ми вузькоспеціалізована школа, навчаємо тільки UI/UX дизайну, маємо лімітований річний набір і не штампуємо дизайнерів —  і це далеко не повний перелік наших переваг.</span>
  //   // <span>Переходь на сайт нашої студії <a href="https://equal.design/">https://equal.design/</a> і переконайся, що ти навчатимешся у професіоналів.</span>`,
  //   //           },
  //   //           {
  //   //             title: 'Що там по працевлаштуванню після курсу?',
  //   //             text: `<span>За роки роботи у нас сформувався список друзів-партнерів, які звертаються до нас за рекомендаціями. І ми залюбки рекомендуємо найкращих випускників. Це і CEO дизайн-студій, а також  HR з відомих IT-компаній. Наші партнери знають про високий рівень підготовки наших студентів, оскільки у цих компаніях працює дуже багато наших випускників. Усі актуальні вакансії ми публікуємо у нашому чаті випускників. Так само портфоліо та резюме найкращих випускників курсу ми розміщуємо на нашій спеціальній сторінці для роботодавців. І трохи нашої вражаючої статистики: 80% наших випускників встигли працевлаштуватися після нашого курсу і працюють UI/UX дизайнерами</span>`,
  //   //           },
  //   //           {
  //   //             title: 'Після курсу залишиться доступ до матеріалів?',
  //   //             text: `<span>Після оплати курсу тобі надається довічний доступ до всіх матеріалів курсу — слайдів лекцій, відео, інструкцій, а також записів розборів ДЗ.</span>`,
  //   //           },
  //   //         ],
  //   //       },
  //   //       ask: {
  //   //         title: 'Лишились питаннячка?',
  //   //         text:
  //   //           "Твій персональний менеджер вже чекає на запитання від тебе! Обирай зручний спосіб зв'язку",
  //   //         buttonTitle: 'Поставити питання',
  //   //       },
  //   //       footer: {
  //   //         contactTitle: 'Контакти',
  //   //         contacts: [
  //   //           {
  //   //             text: '@dk_school',
  //   //             link: 'https://t.me/dk_school',
  //   //             icon: telegram,
  //   //           },
  //   //           {
  //   //             text: '+38 050 300 88 90',
  //   //             link: 'tel:+38 050 300 88 90',
  //   //             icon: phone,
  //   //           },
  //   //           {
  //   //             text: 'hello@designkitchen.com.ua',
  //   //             link: 'mailto:hello@designkitchen.com.ua',
  //   //             icon: email,
  //   //           },
  //   //         ],
  //   //         links: [
  //   //           {
  //   //             title: 'Умови використання',
  //   //             link: 'https://designkitchen.com.ua/useruls.html',
  //   //           },
  //   //           {
  //   //             title: 'Політика конфіденційності',
  //   //             link:
  //   //               'https://designkitchen.com.ua/politika-konfidencialnosti.html',
  //   //           },
  //   //           {
  //   //             title: 'Попередження про авторське право',
  //   //             link: 'https://designkitchen.com.ua/public-doc.html',
  //   //           },
  //   //         ],
  //   //         title: 'Ти ще не записався на курс?',
  //   //         text:
  //   //           'Тоді підписуйся на наші соціальні мережі, ми публікуємо багато корисних матеріалів про дизайн.',
  //   //         socials: [
  //   //           {
  //   //             link: 'https://www.instagram.com/designkitchen.school/',
  //   //             icon: insta,
  //   //           },
  //   //           {
  //   //             link: 'https://www.facebook.com/schooldesignkitchen/',
  //   //             icon: fb,
  //   //           },
  //   //           {
  //   //             link: 'https://www.behance.net/designkitchenschool',
  //   //             icon: be,
  //   //           },
  //   //         ],
  //   //         up: 'Вгору',
  //   //       },
  //   //       contactP: {
  //   //         title: 'Ти обрав формат',
  //   //         text:
  //   //           "Щоб забронювати місце у групі, залиш свій номер телефону. Наш менеджер зв'яжеться з тобою найближчим часом та узгодить усі деталі.",
  //   //         placeholder: 'Твій номер телефону',
  //   //         buttonTitle: 'Забронювати місце',
  //   //         imgs: [icont3, icont2, icont1],
  //   //         subtitle: 'Выберите для себя наиболее подходящий формат обучения',
  //   //         allCourse: '/ весь курс',
  //   //         subtext: 'цена на время военных действий в Украине',
  //   //         possibility: [
  //   //           'Доступ к 30 видео лекциям',
  //   //           'Доступ к 12 практическим занятия в виде скринкастов ',
  //   //           'Дополнительные материалы (статьи/PDF чек-листы/Figma шаблоны)',
  //   //           'Доступ в общий Telegram-чат',
  //   //           'Live Q&A сессии и разборы домашних заданий с менторами',
  //   //           'Обязательное выполнение домашних заданий',
  //   //           'Сертификат по окончанию обучения',
  //   //           'Создание и презентация курсового проекта',
  //   //           'Доступ в закрытый Telegram-чат выпускников ',
  //   //           'Постоянная персональныя менторкая поддержка куратора курса',
  //   //           'Проверка и фидбек по всем  домашним заданиям',
  //   //           'Участие в дизайн-батле',
  //   //         ],
  //   //         item: GlobalState.cost,
  //   //         links: [
  //   //           {
  //   //             title: 'Умови використання',
  //   //             link: 'https://designkitchen.com.ua/useruls.html',
  //   //           },
  //   //           {
  //   //             title: 'Політика конфіденційності',
  //   //             link:
  //   //               'https://designkitchen.com.ua/politika-konfidencialnosti.html',
  //   //           },
  //   //         ],
  //   //         error: 'Некоректний номер',
  //   //       },
  //   //       thanks: {
  //   //         title: 'Твою заявку на бронювання місця в групі успішно прийнято!',
  //   //         subtitle: 'Триває набір на курс, що стартує 21 листопада',
  //   //         text:
  //   //           'Наш менеджер вже знає про заявку та перетелефонує тобі упродовж доби на номер, вказаний при реєстрації. ',
  //   //         subtext:
  //   //           'Давай знайомитись ближче! Переходь на наші сторінки у соціальних мережах.',
  //   //         buttons: [
  //   //           {
  //   //             title: 'Instagram',
  //   //             link: 'https://www.instagram.com/designkitchen.school/',
  //   //           },
  //   //           {
  //   //             title: 'Facebook',
  //   //             link: 'https://www.facebook.com/schooldesignkitchen/',
  //   //           },
  //   //         ],
  //   //         links: [
  //   //           {
  //   //             title: 'Умови використання',
  //   //             link: 'https://designkitchen.com.ua/useruls.html',
  //   //           },
  //   //           {
  //   //             title: 'Політика конфіденційності',
  //   //             link:
  //   //               'https://designkitchen.com.ua/politika-konfidencialnosti.html',
  //   //           },
  //   //         ],
  //   //       },
  //   //       thanksMarathone: {
  //   //         title: 'Твою заявку на бронювання місця на триденному марафоні успішно прийнято!',
  //   //         subtitle: 'Триває набір на курс, що стартує 21 листопада',
  //   //         text:
  //   //           'Наш менеджер вже знає про заявку та відправить тобі протягом доби на номер, вказівок при реєстрації, посилання на чат марафону.',
  //   //         subtext:
  //   //           'Давай знайомитись ближче! Переходь на наші сторінки у соціальних мережах. ',
  //   //         buttons: [
  //   //           {
  //   //             title: 'Instagram',
  //   //             link: 'https://www.instagram.com/designkitchen.school/',
  //   //           },
  //   //           {
  //   //             title: 'Facebook',
  //   //             link: 'https://www.facebook.com/schooldesignkitchen/',
  //   //           },
  //   //         ],
  //   //         links: [
  //   //           {
  //   //             title: 'Умови використання',
  //   //             link: 'https://designkitchen.com.ua/useruls.html',
  //   //           },
  //   //           {
  //   //             title: 'Політика конфіденційності',
  //   //             link:
  //   //               'https://designkitchen.com.ua/politika-konfidencialnosti.html',
  //   //           },
  //   //         ],
  //   //       },
  //   //     }
  // })
};

export const setReview = (review: any) => {
  runInAction(() => {
    GlobalState.review = review;
    GlobalState.isPopShow = true;
  });
};

export const subscribe = async (dt: any) => {
  try {
    const fd = new FormData();
    fd.append("phone", dt);
    fetch("https://designkitchen.com.ua/mail.php", {
      method: "POST",
      body: fd,
    });
  } catch (e) {
    console.log(e);
  }
};

export const send = async (dt: any) => {
  try {
    const fd = new FormData();
    fd.append("name", dt.name);
    fd.append("email", dt.email);
    fd.append("phone", dt.phone);
    fetch("https://designkitchen.com.ua/mail3.php", {
      method: "POST",
      body: fd,
    });
  } catch (e) {
    console.log(e);
  }
};

export const subscribeFormat = async (dt: any) => {
  try {
    const fd = new FormData();
    fd.append("phone", dt.phone);
    fd.append("format", dt.format);
    fetch("https://designkitchen.com.ua/mail2.php", {
      method: "POST",
      body: fd,
    });
  } catch (e) {
    console.log(e);
  }
};

export default GlobalState;
