import { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/flag-caret.svg";
import classNames from "classnames";
import phonecodes from "../../../mocks/phones";
import useClickOutSide from "../../../mocks/ClickOutSide";

const useCountries = (country: string) => {
  const result = useMemo(() => {
    if (!country?.length) return phonecodes;
    const arr: Array<any> = phonecodes.filter((c) => {
      if (
        c.country_en
          .toLowerCase()
          .slice(0, country?.length)
          .includes(country.toLowerCase())
      ) {
        return c;
      }
    });

    return arr;
  }, [country]);

  return result;
};

const FlagsSelect = ({
  setValue,
  currentValue,
}: {
  setValue: (value: any) => void;
  currentValue: any;
}) => {
  const [country, setCountry] = useState("");
  const ref = useRef<any>(null);
  const outside = useClickOutSide(ref);

  useEffect(() => {
    if (outside) {
      setOpen(false);
    }
  }, [outside]);

  const [isOpen, setOpen] = useState(false);
  const countries = useCountries(country);

  const currentCountry:any = phonecodes.find(
    (c) => c.country_code == currentValue.country_code
  );

  return (
    <div
      className={classNames("phone-input__flags", isOpen && "open")}
      ref={ref}
    >
      <div className="phone-input__flags-top" onClick={() => setOpen(!isOpen)}>
        <img
          src={currentCountry?.icon}
          alt="design.kitchen"
          className="phone-input__flag"
        />
        <span>
          {currentCountry?.country_en}
        </span>
        <Arrow />
      </div>

      <div className="phone-input__block">
        <div className="phone-input__block-input">
          <input
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Введіть назву країни"
          />
        </div>
        <div className="phone-input__block-list">
          {countries?.map((c, i) => (
            <div
              className="phone-input__block-item"
              key={i}
              onClick={() => {
                setValue(c);
                setOpen(false);
              }}
            >
              <img
                src={c.icon}
                alt="design.kitchen"
                className="phone-input__flag"
              />
              <span>
                {c.country_en} (+{c.phone_code})
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlagsSelect;
