import { useEffect } from 'react'
import Button from '../common/Button'
import './index.scss'
import img from '../../assets/thanks/main.svg'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
const WOW = require('wow.js')
const ThanksContent = observer(() => {
  const { pathname } = useLocation()

  const content = pathname.includes('marathon')
    ? GlobalState.content?.thanksMarathone
    : GlobalState.content?.thanks

  useEffect(() => {
    new WOW().init()
  }, [])

  if (!content) return <></>
  return (
    <section className="thanks">
      <div className="thanks__container">
        <div className="thanks__col">
          <div className="thanks__subtitle wow animated fadeInUp">
            {content.subtitle}
          </div>
          <div className="thanks__title wow animated fadeInUp">
            {content.title}
          </div>
          <div className="thanks__text wow animated fadeInUp">
            {content.text}
          </div>
          <div className="thanks__subtext wow animated fadeInUp">
            {content.subtext}
          </div>
          <div className="thanks__btn  wow animated fadeInUp">
            {content.buttons.map((b: any, i: number) => (
              <Button
                key={i}
                text={b.title}
                click={() => window.open(b.link, '__blank')}
              />
            ))}
          </div>
        </div>
        <div className="thanks__img  wow animated fadeInUp">
          <img src={img} alt="design.kitchen" />
        </div>
      </div>
      <div className="thanks__bottom">
        {content.links.map((l: any, i: number) => (
          <a
            href={l.link}
            key={i}
            target="__blank"
            className="thanks__bottom-link wow animated fadeInUp"
          >
            {l.title}
          </a>
        ))}
      </div>
    </section>
  )
})
export default ThanksContent
