import './index.scss'
import { ReactComponent as Hand } from '../../assets/portfolio/hand.svg'
import Button from '../common/Button'
import { useEffect } from 'react'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'

const Portfolio = observer(() => {
  const content = GlobalState.content?.portfolio

  if (!content) return <></>
  return (
    <section className="portfolio" id="portfolio">
      <div className="portfolio__container">
        <div className="portfolio__block">
          <div className="portfolio__col">
            <div className="animated wow zoomIn portfolio__hands">
              <Hand />
            </div>
            <div className="portfolio__title wow animated fadeInUp">
              {content.title}
            </div>
            <div className="portfolio__text wow animated fadeInUp">
              {content.text}
            </div>
            <div className="portfolio__row">
              {content.numbers.map((num: any, i: number) => (
                <div
                  className="portfolio__row-col wow animated fadeInUp"
                  key={i}
                >
                  <div className="portfolio__row-title">
                    {num.count}
                    <span>{num.subtitle}</span>
                  </div>
                  <div className="portfolio__row-text">{num.text}</div>
                </div>
              ))}
            </div>
            <Button
              text={content.buttonTitle}
              click={() => {
                window.open(content.buttonLink, '__blank')
              }}
            />
          </div>
          <div className="portfolio__list">
            {content.list.map((li: any, i: number) => (
              <a
                className="portfolio__img  "
                href={li.link}
                target="__blank"
                key={i}
              >
                <img
                  src={li.icon}
                  className="wow animated zoomIn"
                  data-wow-duration="0.85s"
                  data-wow-delay={`${!i || (i + 1) % 2 ? 0.15 : 0.3}s`}
                />
              </a>
            ))}
          </div>
          <Button
            text={content.buttonTitle}
            click={() => {
              window.open(content.buttonLink, '__blank')
            }}
          />
        </div>
      </div>
    </section>
  )
})

export default Portfolio
