import './index.scss'
import { useEffect } from 'react'
import GlobalState from '../../stores/GlobalState'
import { observer } from 'mobx-react'

const Awwards = observer(() => {
  const content = GlobalState.content?.awwards

  if (!content) return <></>
  return (
    <section className="awwards">
      <div className="awwards__container">
        <div className="awwards__title wow animated fadeInUp">
          {content.title}
        </div>
        <div
          className="awwards__text wow animated fadeInUp"
          dangerouslySetInnerHTML={{ __html: content.text }}
        ></div>
        <div className="awwards__list">
          {content.list.map((l: any, i: number) => (
            <div
              className="awwards__item wow animated fadeInUp"
              key={i}
              data-wow-delay={`${0.15 * i}s`}
            >
              <img src={l} alt="design.kitchen" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})

export default Awwards
