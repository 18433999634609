import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './index.scss'
import { ReactComponent as Arrow } from '../../assets/chevron.svg'
import classNames from 'classnames'
import Lottie from 'react-lottie'
import json from '../../lottie/vat.json'
import box from '../../assets/program/Asset 358.svg'
import i1 from '../../assets/program/Asset 222.1.svg'
import i2 from '../../assets/program/Asset 224.1 (1).svg'
import i3 from '../../assets/program/Asset 224.1.svg'
import i4 from '../../assets/program/Asset 225.1.svg'
import i5 from '../../assets/program/Asset 230.1.svg'
import i6 from '../../assets/program/Asset 231.1.svg'
import i7 from '../../assets/program/Asset 234.1.svg'
import i8 from '../../assets/program/Asset 238.1.svg'
import i10 from '../../assets/program/Layer 2.1.svg'
import i11 from '../../assets/program/Layer 2.2.svg'
import i12 from '../../assets/program/Layer 2.3.svg'
import i13 from '../../assets/program/Layer 2.4.svg'
import { observer } from 'mobx-react'
import GlobalState from '../../stores/GlobalState'
const lax = require('lax.js')

const Program = observer(() => {
  const ref = useRef<any>(null)
  const [active, setActive] = useState(0)
  const content = GlobalState.content?.program

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    if (!content) return

    if (ref.current) return
    lax.init()
    lax.addDriver('scrollY', function (laxFrame: any) {
      return window.scrollY
    })

    lax.addElements('.box', {
      scrollY: {
        rotate: [
          ['elInY', 'elOutY-100'],
          [-180, 0],
        ],
      },
    })

    const translateX = [38, -45, 89, 9, -68, 7, 94, -25, 65, -83, -18, 58]

    const skills = document.querySelectorAll('.lax:not(.box)')
    skills.forEach((s, i) => {
      lax.addElement(document.querySelector(`.skill${i + 1}`)!, {
        scrollY: {
          translateY: [
            ['elCenterY-500', 'elOutY-200'],
            [-200, 0],
          ],
          translateX: [
            ['elCenterY', 'elOutY'],
            [translateX[i], translateX[i]],
          ],
          opacity: [
            ['elCenterY-105', 'elOutY-300'],
            [0, 1],
          ],
        },
      })
    })

    ref.current = true
  }, [content])

  if (!content) return <></>
  return (
    <section className="program" id="program">
      <div className="program__container">
        <div className="program__img">
          <div className="program__icons">
            <img
              src={box}
              data-lax-anchor="self"
              className="box lax"
              style={{ transform: 'rotate(-180deg)' }}
            />
            <img
              src={i1}
              className="lax skill1 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(38px)' }}
            />
            <img
              src={i2}
              className="lax skill2 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(-45px)' }}
            />
            <img
              src={i13}
              className="lax skill3 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(89px)' }}
            />
            <img
              src={i4}
              className="lax skill4  skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(9px)' }}
            />
            <img
              src={i5}
              className="lax skill5 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(-68px)' }}
            />
            <img
              src={i6}
              className="lax skill6 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(7px)' }}
            />
            <img
              src={i7}
              className="lax skill7 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(94px)' }}
            />
            <img
              src={i8}
              className="lax skill8 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(-25px)' }}
            />
            <img
              src={i13}
              className="lax skill9 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(65px)' }}
            />
            <img
              src={i10}
              className="lax skill10 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(-83px)' }}
            />
            <img
              src={i11}
              className="lax skill11 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(8px)' }}
            />
            {/* <img
              src={i12}
              className="lax skill12 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(8px)' }}
            /> */}
            <img
              src={i3}
              className="lax skill12 skills"
              data-lax-opacity="300 0, 105 1"
              data-lax-translate-y="200 0, 500 -200"
              style={{ transform: 'translateX(8px)' }}
            />
          </div>
          <Lottie options={defaultOptions} />
        </div>
        <div className="program__col">
          <div className="program__title">{content.title}</div>
          <div className="program__list">
            {content.list.map((li: any, i: number) => (
              <div
                className={classNames(
                  'program__item animated wow fadeInUp',
                  active == i && 'active',
                )}
                key={i}
              >
                <div
                  className="program__item-top"
                  onClick={() => {
                    if (active == i) {
                      setActive(-1)
                    } else setActive(i)
                  }}
                >
                  <div className="program__item-title">{li.title}</div>
                  <Arrow />
                </div>
                <div className="program__item-text">{li.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
})

export default Program
